import React, { useRef, useState } from 'react'
import { brandColor } from '../../../style/brandConfig'
import { hexToRgb } from '../../../utils/colors'
import AddIcon from '../../../assets/Add_to_Favorites.svg'
import CheckmarkIcon from '../../../assets/CheckmarkIcon.svg'
import ReplayIcon from '../../../assets/Play_from_Start.svg'
import './index.css'

type RoundButtonProps = {
  type: 'watchlist' | 'replay'
  onClick?: () => void
  favorited?: boolean | null
}

const RoundButton = ({ onClick, type, favorited }: RoundButtonProps) => {
  //const iconRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)

  const [hover, setHover] = useState(false)

  const brandColorRgb = hexToRgb(brandColor)
  const hoverBackgroundColor = hover
    ? `rgb(${brandColorRgb?.r}, ${brandColorRgb?.g}, ${brandColorRgb?.b}, 0.3)`
    : 'transparent'

  const icon = type === 'watchlist' ? (favorited ? CheckmarkIcon : AddIcon) : type === 'replay' ? ReplayIcon : ''
  const size = type === 'watchlist' ? 16 : type === 'replay' ? 20 : 0
  const tooltipText =
    type === 'watchlist'
      ? favorited
        ? 'Remove from Favorites'
        : 'Add to Favorites'
      : type === 'replay'
      ? 'Replay from Start'
      : ''
  const tooltipMargin = type === 'watchlist' ? (favorited ? -94 : -70) : -65

  const handleOnClick = () => {
    if (type === 'watchlist') {
      onClick?.()
      if (tooltipRef?.current) {
        tooltipRef.current.style.transition = 'none'
        tooltipRef.current.style.opacity = '0'
      }
      if (!favorited) {
        setTimeout(() => {
          if (tooltipRef?.current) {
            tooltipRef.current.style.transition = 'all .3s ease-in-out'
            tooltipRef.current.style.opacity = '1'
          }
        }, 2000)
      } else {
        setTimeout(() => {
          if (tooltipRef?.current) {
            tooltipRef.current.style.transition = 'all .3s ease-in-out'
            tooltipRef.current.style.opacity = '1'
          }
        }, 300)
      }
    } else if (type === 'replay') {
      onClick?.()
    }
  }

  return (
    <div
      className={'buttonContainer'}
      style={styles.buttonContainer}
      onClick={handleOnClick}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <div
        style={{
          ...styles.button,
          transform: hover ? 'scale(1.05)' : 'scale(1)',
          border: `3px solid ${brandColor}`,
          backgroundColor: hoverBackgroundColor,
        }}
      />
      {icon && <img src={icon} style={{ ...styles.icon, height: size, width: size }} alt={''} />}
      <div
        ref={tooltipRef}
        className={'tooltipText'}
        style={{
          ...styles.tooltipText,
          visibility: hover ? 'visible' : 'hidden',
          opacity: hover ? 1 : 0,
          marginLeft: tooltipMargin,
        }}>
        {tooltipText}
      </div>
    </div>
  )
}

const styles = {
  buttonContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
    cursor: 'pointer',
  } as React.CSSProperties,
  button: {
    width: 36,
    height: 36,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  icon: {
    position: 'absolute',
  } as React.CSSProperties,
  tooltipText: {
    width: 'auto',
    color: '#FFFFFF',
    textAlign: 'center',
    borderRadius: 6,
    padding: '8px 10px',
    position: 'absolute',
    zIndex: 1,
    bottom: '120%',
    left: '50%',
    transition: 'all .3s ease-in-out',
    whiteSpace: 'nowrap',
    background: '#262626 0% 0% no-repeat padding-box',
    boxShadow: '0px 6px 25px #000000BF',
    border: '1px solid #404040',
    opacity: 1,
    backdropFilter: 'blur(30px)',
    WebkitBackdropFilter: 'blur(30px)',
  } as React.CSSProperties,
}

export default RoundButton
