import React from 'react'
import MoreDropdown from './MoreDropdown'
import { WindowSize } from '../../../constants/enums'

type MoreSectionProps = {
  windowSize: WindowSize
  items: Link[]
}

const MoreSection = (props: MoreSectionProps) => {
  const { windowSize, items } = props

  return items?.length || windowSize === WindowSize.Mobile ? <MoreDropdown items={items} /> : null
}

export default MoreSection
