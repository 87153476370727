import React, { useState } from 'react'
import { brandColor } from '../../style/brandConfig'
import { useNavigate } from 'react-router-dom'

export type DropdownItemProps = {
  item: Link
  type?: 'more' | 'profile'
}

const DropdownItem = ({ item }: DropdownItemProps) => {
  const navigate = useNavigate()

  const [hovered, setHovered] = useState(false)

  const handleOnClick = () => {
    if (item?.onClick) {
      item?.onClick()
    } else {
      if (item?.route?.path) navigate(item?.route?.path)
    }
  }

  const containerStyle = hovered
    ? {
        ...styles.container,
        color: 'white',
      }
    : {
        ...styles.container,
        color: '#BFBFBF',
      }

  return (
    <div
      style={containerStyle}
      onClick={handleOnClick}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      {item?.text}
    </div>
  )
}

const styles: CSSObject = {
  container: {
    transition: 'color 0.3s ease-in-out',
    cursor: 'pointer',
    lineHeight: '21px',
    fontSize: 18,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textWrapper: {
    marginLeft: 25,
    marginTop: 15,
  } as React.CSSProperties,
  text: {
    color: 'white',
    font: 'SF Pro Text',
    fontWeight: 'bold',
    fontSize: 18,
    paddingBottom: 6.5,
    cursor: 'pointer',
  } as React.CSSProperties,
  profileText: {
    marginLeft: 25,
    color: 'white',
    font: 'SF Pro Text',
    fontWeight: 500,
    fontSize: 16,
    paddingBottom: 17.5,
    opacity: 0.75,
    cursor: 'pointer',
    marginTop: 2.5,
  } as React.CSSProperties,
  hoverProfileText: {
    marginLeft: 25,
    color: 'white',
    font: 'SF Pro Text',
    fontWeight: 500,
    fontSize: 16,
    paddingBottom: 17.5,
    opacity: 1,
    cursor: 'pointer',
    marginTop: 2.5,
  } as React.CSSProperties,
  underline: {
    transition: 'opacity 0.3s, transform 0.3s',
    height: 3,
    backgroundColor: brandColor, // TODO get this value from the brand config
  } as React.CSSProperties,
}

export default DropdownItem
