import React from 'react'
import { useNavigate } from 'react-router-dom'

type FooterBrandProps = {
  src?: string
}
const FooterBrand = ({ src }: FooterBrandProps) => {
  const navigate = useNavigate()
  const imageStyle = { ...styles.image }
  if (process.env.REACT_APP_BRAND_HEIGHT) {
    imageStyle.height = process.env.REACT_APP_BRAND_HEIGHT
  }
  return (
    <div style={styles.container}>
      <img src={src} alt={'logo'} style={imageStyle} onClick={() => navigate('/')} />
    </div>
  )
}

const styles = {
  container: {
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 25,
    display: 'flex',
  } as React.CSSProperties,
  image: {
    height: '100%',
    cursor: 'pointer',
    padding: 10,
  } as React.CSSProperties,
}

export default FooterBrand
