import { Controls as ControlsVid } from '@vidstack/react'
import * as Buttons from './Buttons'
import TimeGroup from './TimeGroup'
import * as Menus from './Menus'
import * as Sliders from './Sliders'
import React from 'react'
import { useMiniMode } from './MiniModeProvider'

const FullVideoControls = () => {
  const [miniMode] = useMiniMode()
  return !miniMode ? (
    <>
      <ControlsVid.Group className={'vds-controls-group'}>
        <Buttons.Close tooltipPlacement={'bottom start'} />
      </ControlsVid.Group>
      <div className={'vds-controls-spacer'} />
      <ControlsVid.Group className={'vds-controls-group'}>
        <Sliders.Time />
      </ControlsVid.Group>
      <ControlsVid.Group className={'vds-controls-group'}>
        <Buttons.Play tooltipPlacement={'top start'} />
        <Buttons.Mute tooltipPlacement={'top'} />
        <Sliders.Volume />
        <TimeGroup />
        <Buttons.Live tooltipPlacement={'top'} />
        <div className={'vds-controls-spacer'} />
        <Buttons.Caption tooltipPlacement={'top'} />
        <Menus.Settings placement={'top end'} tooltipPlacement={'top'} />
        <Buttons.PIP tooltipPlacement={'top'} />
        <Buttons.Fullscreen tooltipPlacement={'top end'} />
      </ControlsVid.Group>
    </>
  ) : null
}

export default FullVideoControls
