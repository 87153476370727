import React, { useCallback, useEffect, useRef, useState } from 'react'
import './index.css'
import MediaItem from '../MediaItem'
import { useAllLazyQuery } from '../../graphql'
import LoadingMediaItems from '../LoadingMediaItems'

type ExploreAllProps = {
  open: boolean
  closeExploreAll: (e: React.MouseEvent<HTMLSpanElement, MouseEvent> | undefined) => void
  id: string | null
  catalogId: string | null
  search?: string | null
}

const ExploreAll = (props: ExploreAllProps) => {
  const { open, closeExploreAll, id, catalogId, search } = props

  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [mobile, setMobile] = useState(false)

  const total = useRef<number>(0)
  const hasMore = useRef<boolean>(false)
  const idRef = useRef<string | null>(null)
  const catalogIdRef = useRef<string | null>(null)
  const searchRef = useRef<string | undefined | null>(null)
  const items = useRef<MediaItem[]>([])
  const loadingMore = useRef<boolean>(false)

  const ref = useRef<HTMLDivElement>(null)

  const [fetchAll] = useAllLazyQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (catalogId) {
      catalogIdRef.current = catalogId
      loadingMore.current = true
      fetchAll({
        variables: {
          rowData: {
            id: catalogIdRef.current,
          },
          itemData: {
            skip: 0,
            take: 100,
          },
        },
      }).then((res) => {
        const itemsRes = res?.data?.all?.row?.items
        const rowTitle = res?.data?.all?.row?.title
        if (itemsRes) {
          items.current = itemsRes?.list
          setTitle(rowTitle ? rowTitle : 'Explore All')
          hasMore.current = itemsRes?.hasMore
          total.current = itemsRes?.total
        }
        loadingMore.current = false
      })
    }
  }, [catalogId])

  useEffect(() => {
    if (id) {
      idRef.current = id
      searchRef.current = search
      loadingMore.current = true
      fetchAll({
        variables: {
          rowData: {
            id: idRef.current,
          },
          itemData: {
            skip: 0,
            take: 100,
            search: searchRef.current,
          },
        },
      }).then((res) => {
        const itemsRes = res?.data?.all?.row?.items
        const rowTitle = res?.data?.all?.row?.title
        if (itemsRes) {
          items.current = itemsRes?.list
          setTitle(rowTitle ? rowTitle : 'Explore All')
          hasMore.current = itemsRes?.hasMore
          total.current = itemsRes?.total
        }
        loadingMore.current = false
      })
    }
  }, [id])

  useEffect(() => {
    setIsOpen(open)
    if (open) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'scroll'
    }
  }, [open])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickListener)
    window.addEventListener('resize', handleResize)
    return () => {
      document.removeEventListener('mousedown', handleClickListener)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleResize = () => {
    setMobile(window.innerWidth < 950)
  }

  const handleOnClose = (e?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    setIsOpen(false)
    setTitle('')
    total.current = 0
    hasMore.current = false
    idRef.current = null
    catalogIdRef.current = null
    searchRef.current = null
    items.current = []
    loadingMore.current = false
    if (closeExploreAll) closeExploreAll(e)
  }

  const handleClickListener = (e: MouseEvent) => {
    if (ref && !ref?.current?.contains(e.target as Node)) {
      handleOnClose()
    }
  }

  const onScroll = useCallback(() => {
    if (ref?.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current
      if (Math.ceil(scrollTop + clientHeight) >= scrollHeight / 2) {
        if (!loadingMore.current && hasMore.current && catalogIdRef.current && items.current.length < total.current) {
          loadingMore.current = true
          fetchAll({
            variables: {
              rowData: {
                id: catalogIdRef.current,
              },
              itemData: {
                skip: items.current.length,
                take: 100,
              },
            },
          }).then((res) => {
            const itemsRes = res?.data?.all?.row?.items
            if (itemsRes) {
              items.current = items.current.concat(itemsRes?.list)
              hasMore.current = itemsRes?.hasMore
              total.current = itemsRes?.total
            }
            loadingMore.current = false
          })
        }
        if (!loadingMore.current && hasMore.current && idRef.current && items.current.length < total.current) {
          loadingMore.current = true
          fetchAll({
            variables: {
              rowData: {
                id: idRef.current,
              },
              itemData: {
                skip: items.current.length,
                take: 100,
                search: searchRef.current,
              },
            },
          }).then((res) => {
            const itemsRes = res?.data?.all?.row?.items
            if (itemsRes) {
              items.current = items.current.concat(itemsRes?.list)
              hasMore.current = itemsRes?.hasMore
              total.current = itemsRes?.total
            }
            loadingMore.current = false
          })
        }
      }
    }
  }, [hasMore, total, items])

  useEffect(() => {
    ref?.current?.addEventListener('scroll', onScroll)
    return () => {
      ref?.current?.removeEventListener('scroll', onScroll)
    }
  }, [onScroll])

  return (
    <div
      className={'exploreAllModal'}
      ref={ref}
      style={{
        ...styles.exploreAllModal,
        border: isOpen ? '1px solid #404040' : 'none',
        height: isOpen ? '97%' : '0%',
        width: mobile ? '92%' : '96%',
        borderTopLeftRadius: mobile ? 10 : 25,
        borderTopRightRadius: mobile ? 10 : 25,
      }}>
      <div style={styles.exploreAllCloseContainer}>
        <div className={'exploreAllClose'} onClick={handleOnClose} />
      </div>
      <span className={'exploreAllTitle'} style={styles.exploreAllTitle}>
        {title}
      </span>
      <div className={'exploreAllMediaItemContainer'} style={styles.exploreAllMediaItemContainer}>
        {!loadingMore.current && !items.current.length
          ? null
          : items?.current?.map((item: MediaItem, index: number) => (
              <div
                key={index}
                className={'exploreAllMediaItem'}
                style={styles.exploreAllMediaItem}
                onClick={handleOnClose}>
                <MediaItem item={item} />
              </div>
            ))}
        {loadingMore.current ? <LoadingMediaItems /> : null}
      </div>
    </div>
  )
}

const styles = {
  exploreAllModal: {
    display: 'flex',
    position: 'fixed',
    height: '0%',
    left: 0,
    right: 0,
    bottom: 0,
    margin: '0 auto',
    overflowY: 'scroll',
    overflowX: 'hidden',
    background: '0% 0% no-repeat padding-box padding-box rgb(38, 38, 38, 0.9)',
    boxShadow: '0px 6px 25px #000000BF',
    opacity: 1,
    backdropFilter: 'blur(30px)',
    WebkitBackdropFilter: 'blur(30px)',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    zIndex: 10,
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  exploreAllCloseContainer: {
    zIndex: 15,
    position: 'sticky',
    width: '100%',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  } as React.CSSProperties,
  exploreAllTitle: {
    textAlign: 'left',
    fontSize: 38,
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
    fontWeight: 700,
    padding: '63px 0 35px 0',
    alignSelf: 'center',
  } as React.CSSProperties,
  exploreAllMediaItemContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 35,
  } as React.CSSProperties,
  exploreAllMediaItem: {
    marginBottom: 10,
  } as React.CSSProperties,
  exploreAllText: {
    color: 'white',
    fontWeight: 700,
    width: '100%',
    marginBottom: 20,
  } as React.CSSProperties,
}

export default ExploreAll
