import { client } from '../index'
import { LiveNowDocument, LiveNowQuery } from '../../graphql'
import { ObservableQuery } from '@apollo/client'
import { LIVE_NOW_SCALE_DOWN_INTERVAL } from '../../constants/service.constant'

let query: ObservableQuery<LiveNowQuery> | undefined
let _onNext: ((liveNow: boolean) => void) | undefined
let _maxRetries: number

type LiveNowParams = {
  pollInterval?: number
  onNext?: typeof _onNext
  maxRetries?: typeof _maxRetries
}

// Default pollInterval is 5 minutes
function start(
  { onNext, pollInterval = LIVE_NOW_SCALE_DOWN_INTERVAL, maxRetries = 3 }: LiveNowParams,
  retryAttempts = 0,
) {
  _onNext = onNext
  _maxRetries = maxRetries
  if (!query) {
    query = client?.watchQuery({
      query: LiveNowDocument,
      fetchPolicy: 'network-only',
    })
    const subscription = query?.subscribe({
      next: ({ data }) => {
        onNext?.(!!data.liveNow?.length)
      },
      error: () => {
        // Retry service on error
        subscription?.unsubscribe()
        // Max retries prevents infinite recursion
        if (retryAttempts < maxRetries) {
          start({ onNext, pollInterval, maxRetries }, retryAttempts + 1)
        } else {
          query?.stopPolling()
        }
      },
    })
  }
  query?.startPolling(pollInterval)
}

function stop() {
  query?.stopPolling()
}

function update(pollInterval: number) {
  start({
    onNext: _onNext,
    pollInterval,
    maxRetries: _maxRetries,
  })
}

export default {
  start,
  stop,
  update,
}
