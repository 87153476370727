import React from 'react'
import { Time, useMediaState } from '@vidstack/react'
import * as Buttons from './Buttons'

type TimeGroupProps = {
  miniMode?: boolean
}

const TimeGroup = ({ miniMode }: TimeGroupProps) => {
  const liveEdge = useMediaState('liveEdge')
  return !miniMode ? (
    <>
      <div className="vds-time-group">
        <Time className="vds-time" type="current" />
        {!liveEdge && (
          <>
            <div className="vds-time-divider">/</div>
            <Time className="vds-time" type="duration" />
          </>
        )}
      </div>
    </>
  ) : (
    <>
      <div className={'vds-time-group'}>
        <Time className="vds-time" type="current" />
      </div>
      <Buttons.Live tooltipPlacement={'top'} />
      <div className="vds-controls-spacer" />
      {!liveEdge && (
        <>
          <div className={'vds-time-group'}>
            <Time className="vds-time" type="duration" />
          </div>
        </>
      )}
    </>
  )
}

export default TimeGroup
