import React, { useState } from 'react'
import { brandColor } from '../../../style/brandConfig'
import { useNavigate } from 'react-router-dom'
import { WindowSize } from '../../../constants/enums'

type NavItemProps = {
  item: Link
  windowSize: WindowSize
}

const NavItem = ({ item, windowSize }: NavItemProps) => {
  const navigate = useNavigate()

  const [hovered, setHovered] = useState(false)

  const opacity = hovered ? 1 : 0
  const transform = hovered ? 'translateY(0px)' : 'translateY(-5px)'

  const handleOnClick = () => {
    if (item?.type === 'external' && item?.url) {
      window.open(item?.url, '_blank', 'noreferrer')
    } else if (item?.type === 'internal' && item?.route?.path) {
      navigate(item?.route?.path)
    }
  }

  return (
    <div
      onClick={handleOnClick}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      key={item?.text}
      style={styles.container}>
      {windowSize === WindowSize.Mobile ? (
        item?.icon ? (
          <img
            src={item?.icon?.src ? item?.icon?.src : item?.icon}
            alt={'icon'}
            style={{ ...styles.icons, ...styles.iconsMobile }}
          />
        ) : null
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {item?.icon ? (
            <img src={item?.icon?.src} alt={'icon'} style={{ ...styles.icons, ...styles.iconsDesktop }} />
          ) : null}
          <div style={{ position: 'relative' }}>
            <div style={styles.text}>{item?.text}</div>
            <div style={{ ...styles.underline, transform, opacity }} />
          </div>
        </div>
      )}
    </div>
  )
}

const styles = {
  container: {
    cursor: 'pointer',
  } as React.CSSProperties,
  containerMobile: {
    height: 18,
    marginTop: 17,
  } as React.CSSProperties,
  text: {
    color: 'white',
    font: 'SF Pro Text',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '21px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  } as React.CSSProperties,
  icons: {
    marginTop: 2,
    height: 17,
  } as React.CSSProperties,
  iconsDesktop: {
    marginRight: 11,
  } as React.CSSProperties,
  iconsMobile: {
    padding: 10,
    marginTop: 0,
    height: 17,
    //paddingBottom: 5,
  } as React.CSSProperties,
  underline: {
    transition: 'opacity 0.3s, transform 0.3s',
    height: 3,
    marginTop: 5,
    position: 'absolute',
    bottom: -8,
    left: 0,
    right: 0,
    backgroundColor: brandColor, // TODO get this value from the brand config
  } as React.CSSProperties,
}

export default NavItem
