let siteBaseUrl: string | null = null
let apiBaseUrl: string | null = null

const config: Config = {
  siteBaseUrl,
  apiBaseUrl,
  mediaBrand: process.env.REACT_APP_MEDIA_BRAND_COLOR || process.env.REACT_APP_BRAND_COLOR,
}

export default config
