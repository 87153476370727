import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectNativeAppMessage } from '../store/base/commonSlice'
import { sendNativeAppMessage } from '../utils/nativeAppMessage'
import { IapInterval, IapPlatform, useRecordIapMutation } from '../graphql'
import { useNavigate } from 'react-router-dom'

const IapListener = () => {
  const nativeAppMessage = useSelector(selectNativeAppMessage)

  const navigate = useNavigate()

  const [recordIap] = useRecordIapMutation()

  useEffect(() => {
    if (nativeAppMessage?.iapResult) {
      sendNativeAppMessage({ message: 'RECEIVED BY WEB APP: ' + JSON.stringify(nativeAppMessage) })
      const iapResult = nativeAppMessage.iapResult
      if (iapResult.success) {
        recordIap({
          variables: {
            data: {
              platform: iapResult.platform as IapPlatform,
              sku: iapResult.sku,
              recurring: iapResult.recurring,
              interval: iapResult.interval as IapInterval,
              contentId: iapResult.contentId,
              bundleId: iapResult.bundleId,
            },
          },
        }).then(() => {
          navigate(`/content/${iapResult.contentId}`)
        })
      }
    }
  }, [nativeAppMessage])

  return null
}

export default IapListener
