import React, { MouseEventHandler } from 'react'
import {
  CaptionButton,
  FullscreenButton,
  isTrackCaptionKind,
  MuteButton,
  PlayButton,
  SeekButton,
  LiveButton,
  Tooltip,
  useMediaState,
  type TooltipPlacement,
  ToggleButton,
  useMediaPlayer,
} from '@vidstack/react'
import {
  ClosedCaptionsIcon,
  ClosedCaptionsOnIcon,
  FullscreenExitIcon,
  FullscreenIcon,
  MuteIcon,
  PauseIcon,
  PictureInPictureExitIcon,
  PictureInPictureIcon,
  PlayIcon,
  SeekBackward10Icon,
  SeekForward10Icon,
  VolumeHighIcon,
  VolumeLowIcon,
  XMarkIcon,
} from '@vidstack/react/icons'
import { useMiniMode } from './MiniModeProvider'
import { useDispatch } from 'react-redux'
import { stopVideo } from '../../../store/video/playerSlice'

export interface MediaButtonProps {
  tooltipPlacement: TooltipPlacement
  onClick?: MouseEventHandler<HTMLButtonElement>
  miniMode?: boolean
}

export function Play({ tooltipPlacement }: MediaButtonProps) {
  const isPaused = useMediaState('paused')
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <PlayButton className="vds-button">{isPaused ? <PlayIcon /> : <PauseIcon />}</PlayButton>
      </Tooltip.Trigger>
      <Tooltip.Content className="vds-tooltip-content" placement={tooltipPlacement}>
        {isPaused ? 'Play' : 'Pause'}
      </Tooltip.Content>
    </Tooltip.Root>
  )
}

export function Mute({ tooltipPlacement }: MediaButtonProps) {
  const volume = useMediaState('volume'),
    isMuted = useMediaState('muted')
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <MuteButton className="vds-button">
          {isMuted || volume == 0 ? <MuteIcon /> : volume < 0.5 ? <VolumeLowIcon /> : <VolumeHighIcon />}
        </MuteButton>
      </Tooltip.Trigger>
      <Tooltip.Content className="vds-tooltip-content" placement={tooltipPlacement}>
        {isMuted ? 'Unmute' : 'Mute'}
      </Tooltip.Content>
    </Tooltip.Root>
  )
}

export function Caption({ tooltipPlacement }: MediaButtonProps) {
  const track = useMediaState('textTrack'),
    isOn = track && isTrackCaptionKind(track)
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <CaptionButton className="vds-button">{isOn ? <ClosedCaptionsOnIcon /> : <ClosedCaptionsIcon />}</CaptionButton>
      </Tooltip.Trigger>
      <Tooltip.Content className="vds-tooltip-content" placement={tooltipPlacement}>
        {isOn ? 'Closed-Captions Off' : 'Closed-Captions On'}
      </Tooltip.Content>
    </Tooltip.Root>
  )
}

export function PIP({ tooltipPlacement }: MediaButtonProps) {
  const [miniMode, setMiniMode] = useMiniMode()

  const onClick = () => {
    if (miniMode) {
      setMiniMode(false)
    } else {
      setMiniMode(true)
    }
  }

  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <ToggleButton className={'vds-button'} onClick={onClick}>
          {miniMode ? <PictureInPictureExitIcon /> : <PictureInPictureIcon />}
        </ToggleButton>
      </Tooltip.Trigger>
      <Tooltip.Content className="vds-tooltip-content" placement={tooltipPlacement}>
        {miniMode ? 'Exit PIP' : 'Enter PIP'}
      </Tooltip.Content>
    </Tooltip.Root>
  )
}

export function Fullscreen({ tooltipPlacement }: MediaButtonProps) {
  const isActive = useMediaState('fullscreen')
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <FullscreenButton className="vds-button">
          {isActive ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </FullscreenButton>
      </Tooltip.Trigger>
      <Tooltip.Content className="vds-tooltip-content" placement={tooltipPlacement}>
        {isActive ? 'Exit Fullscreen' : 'Enter Fullscreen'}
      </Tooltip.Content>
    </Tooltip.Root>
  )
}

export interface SeekButtonProps extends MediaButtonProps {
  seconds: number
}

export function Seek({ seconds, tooltipPlacement }: SeekButtonProps) {
  const isBackward = seconds < 0
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <SeekButton className="vds-button" seconds={seconds}>
          {isBackward ? <SeekBackward10Icon /> : <SeekForward10Icon />}
        </SeekButton>
      </Tooltip.Trigger>
      <Tooltip.Content className="vds-tooltip-content" placement={tooltipPlacement}>
        {isBackward ? 'Seek Backward' : 'Seek Forward'}
      </Tooltip.Content>
    </Tooltip.Root>
  )
}

export function Live({ tooltipPlacement }: MediaButtonProps) {
  const live = useMediaState('live')
  const liveEdge = useMediaState('liveEdge')
  return live ? (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <LiveButton className="vds-live-button">
          <span className="vds-live-button-text">LIVE</span>
        </LiveButton>
      </Tooltip.Trigger>
      {liveEdge ? null : (
        <Tooltip.Content className={'vds-tooltip-content'} placement={tooltipPlacement}>
          Skip ahead to the live stream
        </Tooltip.Content>
      )}
    </Tooltip.Root>
  ) : null
}

export function Close({ tooltipPlacement }: MediaButtonProps) {
  const player = useMediaPlayer()
  const dispatch = useDispatch()
  const [miniMode, setMiniMode] = useMiniMode()

  const onClick = async () => {
    await Promise.all([player?.exitFullscreen(), player?.pause()])
    dispatch(stopVideo())

    if (miniMode) {
      setMiniMode(false)
    }
  }

  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <button className={'vds-button'} onClick={onClick}>
          <XMarkIcon className={'vds-icon'} />
        </button>
      </Tooltip.Trigger>
      <Tooltip.Content className={'vds-tooltip-content'} placement={tooltipPlacement}>
        Close Video
      </Tooltip.Content>
    </Tooltip.Root>
  )
}
