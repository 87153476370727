import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

type PlayerState = {
  src: string | null
  status: 'playing' | 'paused' | 'stopped'
  id: string | null
  position: number | null
  miniMode: boolean
}
export const initialState: PlayerState = {
  src: null,
  status: 'stopped',
  id: null,
  position: null,
  miniMode: false,
}

export const playerSlice = createSlice({
  name: 'video/player',
  initialState,
  reducers: {
    setPlayer: (state, action: PayloadAction<PlayerState>) => {
      state.src = action.payload.src
      state.status = action.payload.status
      state.id = action.payload.id
    },
    playVideo: (state, action: PayloadAction<{ src: string; id: string | null; position?: number | null }>) => {
      state.src = action.payload.src
      state.status = 'playing'
      state.id = action.payload.id
      state.position = action.payload.position === 0 ? 0 : action.payload.position || null
    },
    stopVideo: (state) => {
      state.src = null
      state.status = 'stopped'
    },
    pauseVideo: (state) => {
      state.status = 'paused'
    },
    setMiniMode: (state, action: PayloadAction<boolean>) => {
      state.miniMode = action.payload
    },
  },
})

export const selectPlayer = createSelector(
  (state: { video: { player: PlayerState } }) => {
    return state.video.player
  },
  (player) => player,
)

export const { setPlayer, playVideo, stopVideo, pauseVideo, setMiniMode } = playerSlice.actions

export default playerSlice.reducer
