import React from 'react'
import './index.css'

type ProgressBarProps = {
  progress?: number | null
  live?: boolean
}

const ProgressBar = ({ progress, live = false }: ProgressBarProps) => {
  let percent = progress ? Math.ceil(progress * 100) : 0
  if (percent > 100) {
    percent = 100
  }
  return progress || live ? (
    <div style={styles.container}>
      <div
        style={{
          ...styles.progress,
          width: `${percent}%`,
          backgroundColor: live ? 'rgb(255, 60, 50)' : 'rgb(255, 255, 255)',
        }}
      />
    </div>
  ) : null
}

const styles: CSSObject = {
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 6,
    backgroundColor: 'rgba(180, 180, 180, 0.5)',
    transition: 'all .3s ease-in-out',
  },
  progress: {
    height: '100%',
  },
}

export default ProgressBar
