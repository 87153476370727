const appConfig = {
  apiPrefix: '/api',
  authenticatedEntryPath: '/',
  unAuthenticatedEntryPath: '/',
  tourPath: '/',
  enableMock: true,
  tenantId: process.env.REACT_APP_TENANT_ID,
  tenantName: process.env.REACT_APP_TENANT_NAME,
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    graphQLPath: process.env.REACT_APP_API_GRAPHQL_PATH,
  },
}

export default appConfig
