import React, { useEffect } from 'react'
import MediaRow from '../common/MediaRow'
import { useMyLibraryLazyQuery } from '../graphql'
import { useSelector } from 'react-redux'
import { selectWindowSize } from '../store/base/commonSlice'
import { WindowSize } from '../constants/enums'
import LoadingScreen from '../common/LoadingScreen'

const MyLibrary = () => {
  const windowSize = useSelector(selectWindowSize)
  const [getMyLibrary, { data, loading }] = useMyLibraryLazyQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    getMyLibrary()
  }, [])

  return (
    <div style={styles.container}>
      <div style={windowSize === WindowSize.Mobile ? styles.headerMobile : styles.header}>My Library</div>
      <LoadingScreen loading={loading}>
        <div style={styles.mediaRowWrapper}>
          {data?.myLibrary?.rows.map((row, index) => {
            if (row?.items.list.length) {
              return <MediaRow key={index} {...row} />
            }
          })}
        </div>
      </LoadingScreen>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  } as React.CSSProperties,
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 200,
    marginBottom: -25,
    color: 'white',
    fontWeight: 700,
    fontSize: 64,
    backgroundColor: 'transparent',
    width: '100%',
    zIndex: 1,
    opacity: 1,
    cursor: 'hover',
  } as React.CSSProperties,
  headerMobile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 125,
    marginBottom: -35,
    color: 'white',
    fontWeight: 700,
    fontSize: 28,
    backgroundColor: 'transparent',
    width: '100%',
    zIndex: 1,
    opacity: 1,
    cursor: 'hover',
  } as React.CSSProperties,
  mediaRowWrapper: {
    overflow: 'hidden',
    width: '100%',
    marginTop: '44px',
    marginBottom: '44px',
  } as React.CSSProperties,
}

export default MyLibrary
