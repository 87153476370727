import LiveUpcomingService from './LiveUpcomingService'
import LiveNowService from './LiveNowService'
import { LIVE_NOW_SCALE_DOWN_INTERVAL, LIVE_NOW_SCALE_UP_INTERVAL } from '../../constants/service.constant'

let scaledUp = false

// Scale live now service up to every 30 seconds
function scaleUp(pollInterval = LIVE_NOW_SCALE_UP_INTERVAL) {
  if (!scaledUp) {
    LiveNowService.update(pollInterval)
  }
  scaledUp = true
}

// Scale live now service down to every 5 minutes
function scaleDown(pollInterval = LIVE_NOW_SCALE_DOWN_INTERVAL) {
  if (scaledUp) {
    LiveNowService.update(pollInterval)
  }
  scaledUp = false
}

function start() {
  // The default poll interval of the LiveUpcomingService is 30 seconds
  LiveUpcomingService.start({
    onNext: (liveUpcoming) => {
      if (liveUpcoming) {
        // Live event is upcoming or happening now
        scaleUp()
      }
    },
  })
  // The default poll interval of the LiveNowService is 5 minutes
  LiveNowService.start({
    onNext: (liveNow) => {
      if (liveNow) {
        // Live event is happening now
        scaleUp()
      } else {
        // No live event is happening now
        scaleDown()
      }
    },
  })
}

export default {
  start,
}
