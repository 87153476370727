import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../common/buttons/Button'
import { useSelector } from 'react-redux'
import { selectSession } from '../../store/auth/sessionSlice'

type SubscriptionPaywallProps = {
  contentId?: string
}

const SubscriptionPaywall = ({ contentId }: SubscriptionPaywallProps) => {
  const navigate = useNavigate()
  const { signedIn } = useSelector(selectSession)
  contentId = contentId ? contentId : location.pathname.split('/')[2]
  const subscribeUrl = `/subscribe/content/${contentId}`
  return (
    <>
      <Button
        text={'subscribe'}
        width={122}
        height={36}
        backgroundColor={'#FFFFFF'}
        onClick={() =>
          navigate(signedIn ? subscribeUrl : '/sign-in', {
            state: { redirectUrl: !signedIn ? subscribeUrl : undefined },
          })
        }
      />
    </>
  )
}

export default SubscriptionPaywall
