import { Controls as ControlsVid } from '@vidstack/react'
import * as Buttons from './Buttons'
import TimeGroup from './TimeGroup'
import * as Menus from './Menus'
import * as Sliders from './Sliders'
import React from 'react'
import { useMiniMode } from './MiniModeProvider'

const MiniVideoControls = () => {
  const [miniMode] = useMiniMode()
  return miniMode ? (
    <>
      <ControlsVid.Group className={'vds-controls-group'}>
        <Buttons.Close tooltipPlacement={'bottom start'} />
        <div className={'vds-controls-spacer'} />
        <Buttons.PIP tooltipPlacement={'bottom end'} />
      </ControlsVid.Group>
      <div className={'vds-controls-spacer'} />
      <ControlsVid.Group className={'vds-controls-group justify-center'}>
        <Buttons.Play tooltipPlacement={'top'} />
      </ControlsVid.Group>
      <div className={'vds-controls-spacer'} />
      <ControlsVid.Group className={'vds-controls-group'}>
        <TimeGroup miniMode={true} />
        <Buttons.Caption tooltipPlacement={'top'} />
        <Menus.Settings placement={'top end'} tooltipPlacement={'top'} />
      </ControlsVid.Group>
      <ControlsVid.Group className={'vds-controls-group'}>
        <Sliders.Time />
      </ControlsVid.Group>
    </>
  ) : null
}

export default MiniVideoControls
