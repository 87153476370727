import {
  GateFieldsFragment,
  GateType,
  MediaItemFieldsFragment,
  SubscriptionFieldsFragment,
  SubscriptionItemFieldsFragment,
  SubscriptionStatus,
} from '../graphql'
import { UserState } from '../store/auth/userSlice'

const checkSubscription = (user: UserState, mediaItem: MediaItemFieldsFragment): boolean => {
  let purchasedSubscription = false
  const subscriptionGates = mediaItem?.appliedGates.filter(
    (g) => g.type === GateType.Subscription && g.product?.originId,
  )
  if (subscriptionGates?.length) {
    purchasedSubscription = !!subscriptionGates.find((gate: GateFieldsFragment) => {
      return user?.subscriptions?.find((cusS: SubscriptionFieldsFragment) => {
        if (
          cusS.status !== SubscriptionStatus.Active &&
          cusS.status !== SubscriptionStatus.Trialing &&
          cusS.status !== SubscriptionStatus.Incomplete &&
          cusS.status !== SubscriptionStatus.Unpaid
        ) {
          return false
        }
        return cusS.items.find(
          (item: SubscriptionItemFieldsFragment) => item.price?.product === gate?.product?.originId,
        )
      })
    })
  }
  return purchasedSubscription
}

export default checkSubscription
