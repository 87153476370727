import { ContentRestrictionsType, GateType, MediaItemFieldsFragment } from '../graphql'
import { formatAmount, formatInterval } from './products'

function getPaywallText(
  mediaItem: MediaItemFieldsFragment | null | undefined,
  singleLine: true,
): { text: string; type?: string }

function getPaywallText(
  mediaItem: MediaItemFieldsFragment | null | undefined,
  singleLine?: false,
): {
  paywallInfo: { text: string; type?: string }[]
  skipFirstLine: boolean
}

function getPaywallText(
  mediaItem: MediaItemFieldsFragment | null | undefined,
  singleLine = false,
):
  | { text: string; type?: string }
  | {
      paywallInfo: { text: string; type?: string }[]
      skipFirstLine: boolean
    } {
  const paywallInfo: { text: string; type?: string }[] = []
  let skipFirstLine = false

  if (!mediaItem?.restrictions?.length) {
    if (singleLine) {
      return { text: '' }
    }
    return {
      paywallInfo,
      skipFirstLine,
    }
  }

  if (mediaItem.restrictions.includes(ContentRestrictionsType.Ppv)) {
    const ppvGates = mediaItem.appliedGates.filter((g) => g.type === GateType.Ppv)

    const cheapestPpvGate = ppvGates
      .sort((a, b) => ((a.product?.defaultPrice?.amount || 0) > (b.product?.defaultPrice?.amount || 0) ? 1 : -1))
      .find((g) => g)
    const cheapestPrice = formatAmount(cheapestPpvGate?.product?.defaultPrice?.amount)

    paywallInfo.push({ text: `Available on-demand ${cheapestPrice ? `for ${cheapestPrice}` : ''}`, type: 'ppv' })

    if (singleLine) {
      return paywallInfo[0]
    }
  }

  if (mediaItem.restrictions.includes(ContentRestrictionsType.Bundle)) {
    paywallInfo.push({ text: 'Available as part of a bundle', type: 'bundle' })
    if (singleLine) {
      return paywallInfo[0]
    }
  }

  if (mediaItem.restrictions.includes(ContentRestrictionsType.Subscription)) {
    const subscriptionGates = mediaItem.appliedGates
      .filter((g) => g.type === GateType.Subscription)
      .sort((a, b) => ((a.product?.defaultPrice?.amount || 0) > (b.product?.defaultPrice?.amount || 0) ? 1 : -1))

    if (singleLine && mediaItem.appliedGates.length > 1) {
      const cheapestGate = subscriptionGates[0]
      const cheapestPrice = formatAmount(cheapestGate?.product?.defaultPrice?.amount)
      const interval = formatInterval(cheapestGate?.product?.defaultPrice?.recurring?.interval)
      return {
        text: `Available ${cheapestPrice && interval ? `starting at ${cheapestPrice}/${interval}` : 'by subscription'}`,
        type: 'subscription',
      }
    }

    if (!paywallInfo.length && subscriptionGates.length > 1) {
      skipFirstLine = true
    }

    subscriptionGates.forEach((gate) => {
      const price = formatAmount(gate.product?.defaultPrice?.amount)
      const interval = formatInterval(gate.product?.defaultPrice?.recurring?.interval)
      paywallInfo.push({
        text: `${gate.product?.name}${
          mediaItem.appliedGates.length === 1 && mediaItem.bundles.length === 0 ? ' Exclusive' : ''
        } · ${price}/${interval}`,
        type: 'subscription',
      })
    })

    if (singleLine) {
      return paywallInfo[0]
    }
  }

  if (mediaItem.restrictions.includes(ContentRestrictionsType.Registration)) {
    paywallInfo.push({ text: 'Sign in or register for an account to view this content.', type: 'registration' })
    if (singleLine) return paywallInfo[0]
  }

  return {
    paywallInfo,
    skipFirstLine,
  }
}

export default getPaywallText
