import React from 'react'
import { MediaItemFieldsFragment } from '../../../graphql'
import MediaItemCover from '../../../common/MediaItem/MediaItemCover'

type PpvInfoProps = {
  mediaItem: MediaItemFieldsFragment
}

const PpvInfo = ({ mediaItem }: PpvInfoProps) => {
  return (
    <>
      <div style={styles.mediaItemCoverContainer}>
        <MediaItemCover item={mediaItem} block />
      </div>
      <span style={styles.mediaItemNameText}>{mediaItem.name}</span>
      <span style={styles.mediaItemSubtitleText}>{mediaItem.subtitle}</span>
    </>
  )
}

export default PpvInfo

const styles = {
  mediaItemCoverContainer: {
    marginBottom: 24,
  } as React.CSSProperties,
  mediaItemNameText: {
    fontSize: 26,
    color: '#FFFFFF',
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'left',
    marginBottom: 12,
    display: 'flex',
  } as React.CSSProperties,
  mediaItemSubtitleText: {
    textAlign: 'left',
    fontSize: 17,
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
  } as React.CSSProperties,
}
