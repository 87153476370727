import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

type FooterLinkProps = {
  link: Link
}
const FooterLink = ({ link: { type, url, route, text } }: FooterLinkProps) => {
  const [hover, setHover] = useState(false)
  const navigate = useNavigate()

  const style = { ...styles.container }
  if (hover) {
    style.color = '#FFFFFF'
  }

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={style}
      onClick={() => {
        if (type === 'external' && url) {
          window.open(url, '_blank', 'noreferrer')
        } else if (type === 'internal' && route?.path) {
          navigate(route.path)
        }
      }}>
      {text}
    </div>
  )
}

const styles = {
  container: {
    transition: 'color .35s',
    color: '#BFBFBF',
    fontWeight: 'regular',
    fontSize: 15,
    cursor: 'pointer',
  } as React.CSSProperties,
}

export default FooterLink
