import { combineReducers } from 'redux'
import auth from './auth'
import base from './base'
import exploreAll from './exploreAll'
import video from './video'

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    base,
    exploreAll,
    video,
    ...asyncReducers,
  })
  return combinedReducer(state, action)
}

export default rootReducer
