import React, { useEffect, useRef } from 'react'
import './index.css'
import Slider from 'react-slick'
import Slide from './components/Slide'
import debounce from 'lodash.debounce'

const transitionSpeed = 750

const Carousel = (props: Carousel) => {
  const { items } = props
  let slider = useRef<Slider>(null)

  const NextArrow = () => {
    return (
      <div className={'carouselRightArrowContainer'} style={styles.carouselRightArrowContainer}>
        <div
          className={'carouselRightArrow'}
          style={styles.carouselRightArrow}
          onClick={() => {
            if (slider.current) {
              slider.current.slickNext()
            }
          }}
        />
      </div>
    )
  }
  const PrevArrow = () => {
    return (
      <div className={'carouselLeftArrowContainer'} style={styles.carouselLeftArrowContainer}>
        <div
          className={'carouselLeftArrow'}
          style={styles.carouselLeftArrow}
          onClick={() => {
            if (slider.current) {
              slider.current.slickPrev()
            }
          }}
        />
      </div>
    )
  }

  useEffect(() => {
    window.addEventListener('resize', () => debounce(onResize, 200)())
    onResize()
    return () => {
      window.removeEventListener('resize', () => debounce(onResize, 200)())
    }
  }, [])

  // Calculate the absolute position of the slider dots when slide is landscape
  const onResize = (slide: HTMLDivElement = document.querySelector('.carouselContainer') as HTMLDivElement) => {
    // timeout is needed to get the correct container height after the slide has been resized
    setTimeout(() => {
      const windowWidth = window.innerWidth
      if (windowWidth < 1290) {
        const container = document.querySelector('.carouselContainer') as HTMLDivElement
        if (container) {
          const dots = container.querySelector('.slick-dots') as HTMLUListElement
          const rightSlide = slide.querySelector('.slideRight') as HTMLDivElement
          if (rightSlide && dots) {
            const leftHeight = container.offsetHeight - rightSlide.offsetHeight
            const padding = windowWidth <= 800 ? 30 : 15
            dots.style.bottom = `${leftHeight - padding - 14}px`
          }
        }
      } else {
        const dots = document.querySelector('.slick-dots') as HTMLUListElement
        if (dots) {
          dots.style.bottom = '-29px'
        }
      }
    }, 1)
  }

  const beforeChange = (prev: number, next: number) => {
    if (slider) {
      setTimeout(() => afterChange(next), transitionSpeed)
      const nextSlideElement = slider?.current?.innerSlider?.list?.querySelector(
        `[data-index="${next}"]`,
      ) as HTMLDivElement
      onResize(nextSlideElement)
      const prevSlideElement = slider?.current?.innerSlider?.list?.querySelector(`[data-index="${prev}"]`)
      if (prevSlideElement) {
        prevSlideElement?.querySelector('.slideHeader')?.classList.remove('activeSlide')
        prevSlideElement?.querySelector('.slideHeader')?.classList.add('inactiveSlide')
        prevSlideElement?.querySelector('.slideHeaderDivider')?.classList.remove('activeSlideDivider')
        prevSlideElement?.querySelector('.slideHeaderDivider')?.classList.add('inactiveSlideDivider')
        setTimeout(() => {
          prevSlideElement?.querySelector('.slideTitle')?.classList.remove('activeSlide')
          prevSlideElement?.querySelector('.slideTitle')?.classList.add('inactiveSlide')
          prevSlideElement?.querySelector('.slideLogoContainer')?.classList.remove('activeSlide')
          prevSlideElement?.querySelector('.slideLogoContainer')?.classList.add('inactiveSlide')
          setTimeout(() => {
            prevSlideElement?.querySelector('.slideDescription')?.classList.remove('activeSlide')
            prevSlideElement?.querySelector('.slideDescription')?.classList.add('inactiveSlide')
            setTimeout(() => {
              prevSlideElement?.querySelector('.slideInfoContainer')?.classList.remove('activeSlide')
              prevSlideElement?.querySelector('.slideInfoContainer')?.classList.add('inactiveSlide')
            }, 100)
          }, 100)
        }, 100)
      }
    }
  }
  const afterChange = (current: number) => {
    if (slider) {
      const currentSlideElement = slider?.current?.innerSlider?.list?.querySelector(`[data-index="${current}"]`)
      if (currentSlideElement) {
        currentSlideElement?.querySelector('.slideHeader')?.classList.remove('inactiveSlide')
        currentSlideElement?.querySelector('.slideHeader')?.classList.add('activeSlide')
        currentSlideElement?.querySelector('.slideHeaderDivider')?.classList.remove('inactiveSlideDivider')
        currentSlideElement?.querySelector('.slideHeaderDivider')?.classList.add('activeSlideDivider')
        setTimeout(() => {
          currentSlideElement?.querySelector('.slideTitle')?.classList.remove('inactiveSlide')
          currentSlideElement?.querySelector('.slideTitle')?.classList.add('activeSlide')
          currentSlideElement?.querySelector('.slideLogoContainer')?.classList.remove('inactiveSlide')
          currentSlideElement?.querySelector('.slideLogoContainer')?.classList.add('activeSlide')
          setTimeout(() => {
            currentSlideElement?.querySelector('.slideDescription')?.classList.remove('inactiveSlide')
            currentSlideElement?.querySelector('.slideDescription')?.classList.add('activeSlide')
            setTimeout(() => {
              currentSlideElement?.querySelector('.slideInfoContainer')?.classList.remove('inactiveSlide')
              currentSlideElement?.querySelector('.slideInfoContainer')?.classList.add('activeSlide')
            }, 100)
          }, 100)
        }, 100)
      }
    }
  }
  const onInit = () => {
    const slickSlider = document.querySelector('.carouselContainer .slick-slider') as HTMLDivElement
    if (slickSlider && items.length > 1) {
      slickSlider.style.display = 'flex'
    }
    onResize()
    setTimeout(() => {
      if (slider) {
        const currentSlideElement = slider?.current?.innerSlider?.list?.querySelector(`[data-index="${0}"]`)
        if (currentSlideElement) {
          currentSlideElement?.querySelector('.slideHeader')?.classList.remove('inactiveSlide')
          currentSlideElement?.querySelector('.slideHeader')?.classList.add('activeSlide')
          currentSlideElement?.querySelector('.slideHeaderDivider')?.classList.remove('inactiveSlideDivider')
          currentSlideElement?.querySelector('.slideHeaderDivider')?.classList.add('activeSlideDivider')
          setTimeout(() => {
            currentSlideElement?.querySelector('.slideTitle')?.classList.remove('inactiveSlide')
            currentSlideElement?.querySelector('.slideTitle')?.classList.add('activeSlide')
            currentSlideElement?.querySelector('.slideLogoContainer')?.classList.remove('inactiveSlide')
            currentSlideElement?.querySelector('.slideLogoContainer')?.classList.add('activeSlide')
            setTimeout(() => {
              currentSlideElement?.querySelector('.slideDescription')?.classList.remove('inactiveSlide')
              currentSlideElement?.querySelector('.slideDescription')?.classList.add('activeSlide')
              setTimeout(() => {
                currentSlideElement?.querySelector('.slideInfoContainer')?.classList.remove('inactiveSlide')
                currentSlideElement?.querySelector('.slideInfoContainer')?.classList.add('activeSlide')
              }, 100)
            }, 100)
          }, 100)
        }
      }
    }, 1000)
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 8500,
    Default: 5000,
    draggable: true,
    swipe: true,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange,
    //afterChange,
    onInit,
  }
  return (
    <div className="carouselContainer" style={styles.carouselContainer}>
      <Slider ref={slider} {...settings}>
        {items.map((item, index) => (
          <Slide key={index} mediaItem={item} />
        ))}
      </Slider>
    </div>
  )
}

const styles = {
  carouselContainer: {
    backgroundColor: 'transparent',
    width: '100%',
    marginTop: 0,
  } as React.CSSProperties,
  carouselLeftArrowContainer: {} as React.CSSProperties,
  carouselRightArrowContainer: {} as React.CSSProperties,
  carouselLeftArrow: {
    border: 'solid white',
    borderWidth: '0 3px 3px 0',
    display: 'inline-block',
    padding: 7,
    opacity: 0.5,
    transform: 'rotate(135deg)',
    zIndex: 1,
    cursor: 'pointer',
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  carouselRightArrow: {
    border: 'solid white',
    borderWidth: '0px 3px 3px 0px',
    display: 'inline-block',
    padding: 7,
    opacity: 0.5,
    transform: 'rotate(-45deg)',
    zIndex: 1,
    cursor: 'pointer',
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
}

export default Carousel
