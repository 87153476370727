import React, { useState } from 'react'
import MediaItemCover from '../../../common/MediaItem/MediaItemCover'
import { LiveEvent } from '../../../utils/hooks/useLiveNow'
import { useNavigate } from 'react-router-dom'
import { WindowSize } from '../../../constants/enums'
import { useSelector } from 'react-redux'
import { selectWindowSize } from '../../../store/base/commonSlice'

type LiveNowMediaItemProps = {
  item: LiveEvent
}

const LiveNowMediaItem = ({ item }: LiveNowMediaItemProps) => {
  const [hover, setHover] = useState(false)
  const windowSize = useSelector(selectWindowSize)

  const navigate = useNavigate()

  const titleStyle = windowSize === WindowSize.Mobile ? { ...styles.title, ...styles.titleMobile } : { ...styles.title }

  const textStyle = windowSize === WindowSize.Mobile ? { ...styles.text, ...styles.textMobile } : { ...styles.text }

  const timeRemainingStyle =
    windowSize === WindowSize.Mobile
      ? { ...styles.timeRemaining, ...styles.timeRemainingMobile }
      : { ...styles.timeRemaining }

  return (
    <div
      style={styles.container}
      onClick={() => navigate(`/content/${item?.id}`)}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <MediaItemCover item={item} size={'sm'} hover={hover} />
      <div style={textStyle}>
        <div style={titleStyle}>{item.name}</div>
        <div style={timeRemainingStyle}>{item.minutesRemainingText}</div>
      </div>
    </div>
  )
}

const styles: CSSObject = {
  container: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    gap: 22.5,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6.5,
    textAlign: 'left',
    font: 'SF Pro Text',
    lineHeight: '21.5px',
    fontWeight: 500,
  },
  textMobile: {
    lineHeight: '18px',
    gap: 5,
  },
  title: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    fontSize: 16,
    fontWeight: 500,
  },
  titleMobile: {
    fontSize: 15,
  },
  timeRemaining: {
    fontSize: 14,
    color: '#808080',
  },
  timeRemainingMobile: {
    fontSize: 15,
  },
}

export default LiveNowMediaItem
