import { useEffect } from 'react'
import { FallbackProps } from 'react-error-boundary'

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  useEffect(() => {
    console.log(error)
  }, [error, resetErrorBoundary])

  // Todo: return a custom error page / message
  return null
}

export default ErrorFallback
