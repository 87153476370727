import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import MediaItem from '../MediaItem'
import Slider from 'react-slick'
import ArrowIcon from '../../assets/Row Scroll Arrow.svg'
import { MediaRowFieldsFragment } from '../../graphql'
import { useDispatch, useSelector } from 'react-redux'
import { selectExploreAll, setExploreAll } from '../../store/exploreAll/exploreAllSlice'

type MediaRowProps = Partial<MediaRowFieldsFragment>

const MediaRow = (props: MediaRowProps) => {
  const { id, title, items = { total: 0, hasMore: false, list: [] }, catalogId } = props
  const { list, total } = items
  const [showLeftArrow, setShowLeftArrow] = useState(false)

  let slider = useRef<Slider>(null)

  const dispatch = useDispatch()
  const { search } = useSelector(selectExploreAll)

  const handleOpenExploreAll = () => {
    dispatch(setExploreAll({ open: true, id: id || null, catalogId: catalogId || null, search }))
  }

  const NextArrow = () => {
    return (
      <div
        className={'mediaRowRightArrowContainer'}
        style={styles.mediaRowRightArrowContainer}
        onClick={() => {
          if (slider.current) {
            slider.current.slickNext()
          }
        }}>
        <img src={ArrowIcon} alt={''} className={'mediaRowRightArrow'} style={styles.mediaRowRightArrow} />
      </div>
    )
  }

  const PrevArrow = () => {
    return (
      <div
        className={'mediaRowLeftArrowContainer'}
        style={styles.mediaRowLeftArrowContainer}
        onClick={() => {
          if (slider.current) {
            slider.current.slickPrev()
          }
        }}>
        <img
          src={ArrowIcon}
          alt={''}
          className={'mediaRowLeftArrow' + (showLeftArrow ? '' : ' hidden')}
          style={styles.mediaRowLeftArrow}
        />
      </div>
    )
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    fade: false,
    autoplay: false,
    Default: 5000,
    draggable: true,
    swipe: true,
    variableWidth: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1793,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1453,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1103,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 714,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 545,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 374,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: (index: number) => {
      if (index === 0) {
        setShowLeftArrow(false)
      } else {
        setShowLeftArrow(true)
      }
    },
  }

  useEffect(() => {
    const leftArrow =
      slider.current?.innerSlider?.list?.parentElement?.firstElementChild?.querySelector('.mediaRowLeftArrow')

    if (showLeftArrow) {
      leftArrow?.classList?.remove('hidden')
    } else {
      leftArrow?.classList?.add('hidden')
    }
  }, [showLeftArrow])

  const [dragging, setDragging] = useState(false)

  return (
    <div className={'mediaRowContainer'} style={styles.mediaRowContainer} key={title}>
      <div className={'mediaRowTitleContainer'} style={styles.mediaRowTitleContainer}>
        <span className={'mediaRowTitle'} style={styles.mediaRowTitle}>
          {title}
        </span>
        <div
          className={'mediaRowDivider'}
          style={{
            ...styles.mediaRowDivider,
            background: 'transparent',
          }}
        />
        {(!total && list.length) || total > list.length ? (
          <span className={'mediaRowAll'} style={styles.mediaRowAll} onClick={handleOpenExploreAll}>
            Explore All
          </span>
        ) : null}
      </div>
      {list.length ? (
        <Slider ref={slider} {...settings}>
          {list.map((item, index) => (
            <div key={index} onMouseMove={() => setDragging(true)} onMouseDown={() => setDragging(false)}>
              <MediaItem item={item} disableClick={dragging} />
            </div>
          ))}
        </Slider>
      ) : null}
    </div>
  )
}

const styles = {
  mediaRowContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: '25px 90px',
    position: 'relative',
  } as React.CSSProperties,
  mediaRowTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  } as React.CSSProperties,
  mediaRowDivider: {
    width: '100%',
    height: 1,
    marginTop: 5,
  } as React.CSSProperties,
  mediaRowAll: {
    justifySelf: 'flex-end',
    fontSize: 17,
    fontWeight: 500,
    letterSpacing: 0,
    color: '#808080',
    opacity: 1,
    marginLeft: 20,
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  mediaRowLeftArrowContainer: {
    position: 'absolute',
    height: 192,
    width: 96,
    zIndex: 1,
    opacity: 0.5,
    left: -90,
    top: 22,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  mediaRowRightArrowContainer: {
    position: 'absolute',
    height: 192,
    width: 96,
    zIndex: 1,
    opacity: 0.5,
    right: -90,
    top: 22,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  mediaRowRightArrow: {
    height: 100,
    width: '100%',
    opacity: 0,
    zIndex: 1,
    transition: 'all .3s ease-in-out',
    cursor: 'pointer',
  } as React.CSSProperties,
  mediaRowLeftArrow: {
    height: 100,
    width: '100%',
    opacity: 0,
    zIndex: 1,
    transform: 'scaleX(-1)',
    transition: 'all .3s ease-in-out',
    cursor: 'pointer',
  } as React.CSSProperties,
  mediaRowTitle: {
    justifySelf: 'flex-start',
    fontSize: 22,
    fontWeight: 700,
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
    whiteSpace: 'nowrap',
    marginRight: 20,
  } as React.CSSProperties,
}

export default MediaRow
