import React, { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { hexToRgb } from '../utils/colors'
import { brandColor } from '../style/brandConfig'
import { CSSProperties } from 'react'

type Position = CSSProperties['position']

type LoadingScreenProps = {
  loading?: boolean
  children?: React.ReactNode
}
const LoadingScreen = ({ loading = false, children }: LoadingScreenProps) => {
  const [mobile, setMobile] = React.useState(false)
  const brandRGB = hexToRgb(brandColor)

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  const onResize = () => {
    if (window.innerWidth <= 800) {
      if (!mobile) {
        setMobile(true)
      }
    } else {
      if (mobile) {
        setMobile(false)
      }
    }
  }

  let r = brandRGB?.r ? Math.floor(brandRGB.r / 3) : 0
  let g = brandRGB?.g ? Math.floor(brandRGB.g / 3) : 0
  let b = brandRGB?.b ? Math.floor(brandRGB.b / 3) : 0

  let dr = brandRGB?.r ? Math.floor(brandRGB.r / 6) : 0
  let dg = brandRGB?.g ? Math.floor(brandRGB.g / 6) : 0
  let db = brandRGB?.b ? Math.floor(brandRGB.b / 6) : 0

  const highlightColor = `rgba(${r}, ${g}, ${b}, 1)`
  const darkBrandColor = `rgba(${dr}, ${dg}, ${db}, 1)`

  const screen = loading
    ? {
        opacity: 0,
        position: 'fixed' as Position,
        transform: 'scale(0.99) translateY(20px)',
      }
    : {
        opacity: 1,
        position: 'relative' as Position,
        transform: undefined,
      }

  const skeletonWrapper = mobile ? styles.mobileSkeletonWrapper : styles.skeletonWrapper

  return (
    <>
      <div id={'screenWrapper'} style={{ ...styles.screenWrapper, ...screen }}>
        {children}
      </div>
      {loading ? (
        <div style={skeletonWrapper}>
          <Skeleton
            count={1}
            height={24}
            borderRadius={12}
            highlightColor={highlightColor}
            baseColor={darkBrandColor}
            width={200}
            style={{
              lineHeight: 1,
              display: 'flex',
            }}
          />
          <Skeleton
            count={1}
            height={200}
            highlightColor={highlightColor}
            baseColor={darkBrandColor}
            style={{
              lineHeight: 1,
              marginBottom: 75,
              display: 'flex',
            }}
          />
          <Skeleton
            count={1}
            height={24}
            borderRadius={12}
            highlightColor={highlightColor}
            baseColor={darkBrandColor}
            width={200}
            style={{
              lineHeight: 1,
              display: 'flex',
            }}
          />
          <Skeleton
            count={1}
            height={200}
            highlightColor={highlightColor}
            baseColor={darkBrandColor}
            style={{
              lineHeight: 1,
              marginBottom: 75,
              display: 'flex',
            }}
          />
          <Skeleton
            count={1}
            height={24}
            borderRadius={12}
            highlightColor={highlightColor}
            baseColor={darkBrandColor}
            width={200}
            style={{
              lineHeight: 1,
              display: 'flex',
            }}
          />
          <Skeleton
            count={1}
            height={200}
            highlightColor={highlightColor}
            baseColor={darkBrandColor}
            style={{
              lineHeight: 1,
              marginBottom: 75,
              display: 'flex',
            }}
          />
        </div>
      ) : null}
    </>
  )
}

const styles = {
  skeletonWrapper: {
    paddingTop: 175,
    paddingLeft: 75,
    paddingRight: 75,
    paddingBottom: 100,
  },
  mobileSkeletonWrapper: {
    paddingTop: 90,
    paddingLeft: 36,
    paddingRight: 36,
    paddingBottom: 0,
  },
  screenWrapper: {
    opacity: 0,
    //overflow: 'hidden',
    position: 'absolute' as Position,
    transform: 'scale(0.99) translateY(20px)',
    transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
  },
}

export default LoadingScreen
