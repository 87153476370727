import React from 'react'
import DropdownMenu from './DropdownMenu'

import './index.css'

type DropdownProps = {
  children: React.ReactNode
}

const Dropdown = ({ children }: DropdownProps) => {
  const ref = React.useRef<HTMLDivElement>(null)

  return (
    <div
      ref={ref}
      style={{ position: 'relative' }}
      className={'dropdown'}
      onMouseOver={() => {
        ref.current?.classList.remove('dropdown-hidden')
      }}>
      {Array.isArray(children)
        ? children?.map((child) => {
            if (child.type === DropdownMenu) {
              return React.cloneElement(child, {
                ...child.props,
                ref,
              })
            }
            return child
          })
        : typeof children === 'object' && children && 'type' in children && children.type === DropdownMenu
        ? React.cloneElement(children, {
            ...children.props,
            ref,
          })
        : children}
    </div>
  )
}

Dropdown.Menu = DropdownMenu

export default Dropdown
