import { createSelector, createSlice } from '@reduxjs/toolkit'
import { SubscriptionFieldsFragment } from '../../graphql'

export type UserState = {
  id: string
  avatar: string
  userName: string
  firstName: string
  lastName: string
  email: string
  subscriptions: SubscriptionFieldsFragment[]
  authority: string[]
}

export const initialState: UserState = {
  id: '',
  avatar: '',
  userName: '',
  firstName: '',
  lastName: '',
  email: '',
  authority: [],
  subscriptions: [],
}

export const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (_, action) => action.payload,
    userLoggedOut: () => initialState,
  },
})

export const selectUser = createSelector(
  (state: { auth: { user: UserState } }) => state.auth.user,
  (user) => user,
)

export const { setUser } = userSlice.actions

export default userSlice.reducer
