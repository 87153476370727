import React, { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { hexToRgb } from '../utils/colors'
import { brandColor } from '../style/brandConfig'

const LoadingMediaItems = () => {
  const [mobile, setMobile] = React.useState(false)
  const brandRGB = hexToRgb(brandColor)

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  const onResize = () => {
    if (window.innerWidth <= 800) {
      if (!mobile) {
        setMobile(true)
      }
    } else {
      if (mobile) {
        setMobile(false)
      }
    }
  }

  let r = brandRGB?.r ? Math.floor(brandRGB.r / 3) : 0
  let g = brandRGB?.g ? Math.floor(brandRGB.g / 3) : 0
  let b = brandRGB?.b ? Math.floor(brandRGB.b / 3) : 0

  let dr = brandRGB?.r ? Math.floor(brandRGB.r / 6) : 0
  let dg = brandRGB?.g ? Math.floor(brandRGB.g / 6) : 0
  let db = brandRGB?.b ? Math.floor(brandRGB.b / 6) : 0

  const highlightColor = `rgba(${r}, ${g}, ${b}, 1)`
  const darkBrandColor = `rgba(${dr}, ${dg}, ${db}, 1)`

  return (
    <>
      <div style={{ padding: 6 }}>
        <Skeleton
          count={1}
          height={mobile ? 84 : 187}
          borderRadius={12}
          highlightColor={highlightColor}
          baseColor={darkBrandColor}
          width={mobile ? 160 : 338}
          style={{
            lineHeight: 1,
            display: 'flex',
          }}
        />
        <Skeleton
          count={1}
          height={mobile ? 40 : 42}
          borderRadius={12}
          highlightColor={highlightColor}
          baseColor={darkBrandColor}
          width={mobile ? 160 : 300}
          style={{
            lineHeight: 1,
            display: 'flex',
            top: -5,
          }}
        />
      </div>
      <div style={{ padding: 6 }}>
        <Skeleton
          count={1}
          height={mobile ? 84 : 187}
          borderRadius={12}
          highlightColor={highlightColor}
          baseColor={darkBrandColor}
          width={mobile ? 160 : 338}
          style={{
            lineHeight: 1,
            display: 'flex',
          }}
        />
        <Skeleton
          count={1}
          height={mobile ? 40 : 42}
          borderRadius={12}
          highlightColor={highlightColor}
          baseColor={darkBrandColor}
          width={mobile ? 160 : 300}
          style={{
            lineHeight: 1,
            display: 'flex',
            top: -5,
          }}
        />
      </div>
      <div style={{ padding: 6 }}>
        <Skeleton
          count={1}
          height={mobile ? 84 : 187}
          borderRadius={12}
          highlightColor={highlightColor}
          baseColor={darkBrandColor}
          width={mobile ? 160 : 338}
          style={{
            lineHeight: 1,
            display: 'flex',
          }}
        />
        <Skeleton
          count={1}
          height={mobile ? 40 : 42}
          borderRadius={12}
          highlightColor={highlightColor}
          baseColor={darkBrandColor}
          width={mobile ? 160 : 300}
          style={{
            lineHeight: 1,
            display: 'flex',
            top: -5,
          }}
        />
      </div>
      <div style={{ padding: 6 }}>
        <Skeleton
          count={1}
          height={mobile ? 84 : 187}
          borderRadius={12}
          highlightColor={highlightColor}
          baseColor={darkBrandColor}
          width={mobile ? 160 : 338}
          style={{
            lineHeight: 1,
            display: 'flex',
          }}
        />
        <Skeleton
          count={1}
          height={mobile ? 40 : 42}
          borderRadius={12}
          highlightColor={highlightColor}
          baseColor={darkBrandColor}
          width={mobile ? 160 : 300}
          style={{
            lineHeight: 1,
            display: 'flex',
            top: -5,
          }}
        />
      </div>
      <div style={{ padding: 6 }}>
        <Skeleton
          count={1}
          height={mobile ? 84 : 187}
          borderRadius={12}
          highlightColor={highlightColor}
          baseColor={darkBrandColor}
          width={mobile ? 160 : 338}
          style={{
            lineHeight: 1,
            display: 'flex',
          }}
        />
        <Skeleton
          count={1}
          height={mobile ? 40 : 42}
          borderRadius={12}
          highlightColor={highlightColor}
          baseColor={darkBrandColor}
          width={mobile ? 160 : 300}
          style={{
            lineHeight: 1,
            display: 'flex',
            top: -5,
          }}
        />
      </div>
      <div style={{ padding: 6 }}>
        <Skeleton
          count={1}
          height={mobile ? 84 : 187}
          borderRadius={12}
          highlightColor={highlightColor}
          baseColor={darkBrandColor}
          width={mobile ? 160 : 338}
          style={{
            lineHeight: 1,
            display: 'flex',
          }}
        />
        <Skeleton
          count={1}
          height={mobile ? 40 : 42}
          borderRadius={12}
          highlightColor={highlightColor}
          baseColor={darkBrandColor}
          width={mobile ? 160 : 300}
          style={{
            lineHeight: 1,
            display: 'flex',
            top: -5,
          }}
        />
      </div>
      <div style={{ padding: 6 }}>
        <Skeleton
          count={1}
          height={mobile ? 84 : 187}
          borderRadius={12}
          highlightColor={highlightColor}
          baseColor={darkBrandColor}
          width={mobile ? 160 : 338}
          style={{
            lineHeight: 1,
            display: 'flex',
          }}
        />
        <Skeleton
          count={1}
          height={mobile ? 40 : 42}
          borderRadius={12}
          highlightColor={highlightColor}
          baseColor={darkBrandColor}
          width={mobile ? 160 : 300}
          style={{
            lineHeight: 1,
            display: 'flex',
            top: -5,
          }}
        />
      </div>
      <div style={{ padding: 6 }}>
        <Skeleton
          count={1}
          height={mobile ? 84 : 187}
          borderRadius={12}
          highlightColor={highlightColor}
          baseColor={darkBrandColor}
          width={mobile ? 160 : 338}
          style={{
            lineHeight: 1,
            display: 'flex',
          }}
        />
        <Skeleton
          count={1}
          height={mobile ? 40 : 42}
          borderRadius={12}
          highlightColor={highlightColor}
          baseColor={darkBrandColor}
          width={mobile ? 160 : 300}
          style={{
            lineHeight: 1,
            display: 'flex',
            top: -5,
          }}
        />
      </div>
    </>
  )
}

export default LoadingMediaItems
