import React from 'react'
import FooterLink from './FooterLink'
import FooterBrand from './FooterBrand'
import { useSelector } from 'react-redux'
import { selectBrandData } from '../../store/base/commonSlice'
import { FooterFieldsFragment } from '../../graphql'

type FooterProps = {
  data?: FooterFieldsFragment | null
}

const Footer = ({ data }: FooterProps) => {
  const brand = useSelector(selectBrandData)
  return data ? (
    <div style={styles.container}>
      <FooterBrand src={brand?.icon?.src} />
      <div style={styles.links}>
        {data?.links?.map((link, index: number) => link && <FooterLink key={index} link={link} />)}
      </div>
    </div>
  ) : null
}

const styles = {
  container: {
    paddingTop: 26,
    paddingBottom: 33,
    width: '100%',
    backgroundColor: '#1F1F1F',
    marginTop: 'auto',
    zIndex: 1,
  } as React.CSSProperties,
  links: {
    display: 'flex',
    gap: 25,
    flexDirection: 'row',
    maxWidth: 525,
    margin: 'auto',
    justifyContent: 'center',
    flexWrap: 'wrap',
  } as React.CSSProperties,
}
export default Footer
