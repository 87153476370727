import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../common/buttons/Button'
import { useSelector } from 'react-redux'
import { selectSession } from '../../store/auth/sessionSlice'

type PpvPaywallProps = {
  contentId?: string
}

const PpvPaywall = ({ contentId }: PpvPaywallProps) => {
  const navigate = useNavigate()
  const { signedIn } = useSelector(selectSession)
  contentId = contentId ? contentId : location.pathname.split('/')[2]
  const purchaseUrl = `/purchase/${contentId}`

  return (
    <>
      <Button
        text={'buy now'}
        width={106}
        height={36}
        backgroundColor={'#FFFFFF'}
        onClick={() =>
          navigate(signedIn ? purchaseUrl : '/sign-in', { state: { redirectUrl: !signedIn ? purchaseUrl : undefined } })
        }
      />
    </>
  )
}

export default PpvPaywall
