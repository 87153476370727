import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import { brandColor } from '../../style/brandConfig'
import Slider from 'react-slick'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../../common/buttons/Button'
import useAuth from '../../utils/hooks/useAuth'
import { useSelector } from 'react-redux'
import { selectBrandData, selectWindowSize } from '../../store/base/commonSlice'
import { WindowSize } from '../../constants/enums'

const Registration = () => {
  const windowSize = useSelector(selectWindowSize)
  const brand = useSelector(selectBrandData)

  const location = useLocation()
  const redirectUrl = location.state?.redirectUrl

  const navigate = useNavigate()
  const { register } = useAuth()

  let registrationSlider = useRef<Slider>(null)

  const [firstName, _setFirstName] = useState('')
  const [lastName, _setLastName] = useState('')
  const [emailAddress, _setEmailAddress] = useState('')
  const [password, _setPassword] = useState('')
  const [confirmPassword, _setConfirmPassword] = useState('')
  //const [verifyCode, setVerifyCode] = useState('')

  const [invalidFirstName, setInvalidFirstName] = useState(false)
  const [invalidLastName, setInvalidLastName] = useState(false)
  const [invalidEmailAddress, setInvalidEmailAddress] = useState(false)
  const [invalidPassword, setInvalidPassword] = useState<string | null>(null)
  const [invalidConfirmPassword, setInvalidConfirmPassword] = useState(false)

  const [slideNumber, setSlideNumber] = useState(1)
  const [isSubmitting, setSubmitting] = useState(false)

  const firstNameInputRef = useRef<HTMLInputElement>(null)
  const passwordInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    firstNameInputRef.current?.focus()
  }, [])

  const inputs = document.querySelectorAll('input.registrationCodeInput') as NodeListOf<HTMLInputElement>

  useEffect(() => {
    inputs.forEach((element, index) => {
      element.addEventListener('keydown', (e) => {
        const target = e?.target as HTMLInputElement
        if (e.keyCode === 8 && target?.value === '') inputs[Math.max(0, index - 1)].focus()
        handleSetVerifyCode()
      })
      element.addEventListener('input', (e) => {
        const target = e?.target as HTMLInputElement
        if (target) {
          // TODO Figure out lint error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const [first, ...rest] = target ? target.value : ''
          target.value = first ?? ''
          const last = index === inputs.length - 1
          const inserted = first !== undefined
          if (inserted && !last) {
            inputs[index + 1].focus()
            inputs[index + 1].value = rest.join('')
            inputs[index + 1].dispatchEvent(new Event('input'))
          }
        }
        handleSetVerifyCode()
      })
    })
  })

  const handleSetVerifyCode = () => {
    let code = [] as string[]
    inputs.forEach(({ value }) => code.push(value))
    //setVerifyCode(code.join(''))
  }

  const handleOnRegister = async () => {
    if (validatePassword() && validateConfirmPassword()) {
      setSubmitting(true)
      const result = await register({
        firstName,
        lastName,
        email: emailAddress,
        password,
      })
      setSubmitting(false)
      if (result.status === 'failed') {
        setInvalidPassword(result.message)
      } else {
        registrationSlider?.current?.slickNext()
      }
    }
  }

  const setFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    _setFirstName(e.target.value)
    if (e.target.value) {
      setInvalidFirstName(false)
    }
  }
  const validateFirstName = () => {
    if (firstName) {
      setInvalidFirstName(false)
      return true
    } else {
      setInvalidFirstName(true)
      return false
    }
  }

  const setLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    _setLastName(e.target.value)
    if (e.target.value) {
      setInvalidLastName(false)
    }
  }
  const validateLastName = () => {
    if (lastName) {
      setInvalidLastName(false)
      return true
    } else {
      setInvalidLastName(true)
      return false
    }
  }

  const setEmailAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    _setEmailAddress(e.target.value)
    if (e.target.value && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
      setInvalidEmailAddress(false)
    }
  }
  const validateEmailAddress = () => {
    if (emailAddress && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)) {
      setInvalidEmailAddress(false)
      return true
    } else {
      setInvalidEmailAddress(true)
      return false
    }
  }

  const backToNameAndEmailSlide = () => {
    registrationSlider?.current?.slickPrev()
    setSlideNumber(1)
    firstNameInputRef.current?.focus()
  }

  const advanceToPasswordSlide = () => {
    if (validateFirstName() && validateLastName() && validateEmailAddress()) {
      registrationSlider?.current?.slickNext()
      setSlideNumber(2)
      passwordInputRef.current?.focus()
    }
  }

  const setPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    _setPassword(e.target.value)
    if (e.target.value && e.target.value.length >= 6) {
      setInvalidPassword(null)
    }
  }
  const validatePassword = () => {
    if (password && password.length >= 6) {
      setInvalidPassword(null)
      return true
    } else {
      setInvalidPassword('Password must contain at least six digits')
      return false
    }
  }

  const setConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    _setConfirmPassword(e.target.value)
    if (e.target.value && e.target.value === password) {
      setInvalidConfirmPassword(false)
    }
  }
  const validateConfirmPassword = () => {
    if (password && confirmPassword && password === confirmPassword) {
      setInvalidConfirmPassword(false)
      return true
    } else {
      setInvalidConfirmPassword(true)
      return false
    }
  }

  const registrationFirstSlide = () => {
    return (
      <div style={styles.registrationSlide}>
        <div style={styles.registrationHeaderContainer}>
          <span style={styles.registrationHeader}>REGISTER</span>
          <div style={styles.registrationHeaderDivider} />
        </div>
        <div style={styles.registrationInputForm}>
          <span style={styles.registrationFormHeader}>Enter your name and email</span>
          <div style={styles.registrationInputContainer}>
            <input
              type="text"
              id="firstName"
              name="firstName"
              autoComplete={'off'}
              placeholder={'First Name'}
              required
              value={firstName}
              onKeyDown={(e) => e.key === 'Enter' && advanceToPasswordSlide()}
              onBlur={validateFirstName}
              onChange={setFirstName}
              style={{
                ...styles.registrationInput,
                border: `3px solid ${invalidFirstName ? '#FF4848' : 'transparent'}`,
              }}
              tabIndex={slideNumber === 1 ? undefined : -1}
              ref={firstNameInputRef}
            />
            {invalidFirstName && <span style={styles.registrationErrorText}>Enter your first name.</span>}
          </div>
          <div style={styles.registrationInputContainer}>
            <input
              type="text"
              id="lastName"
              name="lastName"
              autoComplete={'off'}
              placeholder={'Last Name'}
              required
              value={lastName}
              onKeyDown={(e) => e.key === 'Enter' && advanceToPasswordSlide()}
              onBlur={validateLastName}
              onChange={setLastName}
              style={{
                ...styles.registrationInput,
                border: `3px solid ${invalidLastName ? '#FF4848' : 'transparent'}`,
              }}
              tabIndex={slideNumber === 1 ? undefined : -1}
            />
            {invalidLastName && <span style={styles.registrationErrorText}>Enter your last name.</span>}
          </div>
          <div style={styles.registrationInputContainer}>
            <input
              type="text"
              id="emailAddress"
              name="emailAddress"
              autoComplete={'off'}
              placeholder={'Email Address'}
              required
              value={emailAddress}
              onKeyDown={(e) => e.key === 'Enter' && advanceToPasswordSlide()}
              onBlur={validateEmailAddress}
              onChange={setEmailAddress}
              style={{
                ...styles.registrationInput,
                border: `3px solid ${invalidEmailAddress ? '#FF4848' : 'transparent'}`,
              }}
              tabIndex={slideNumber === 1 ? undefined : -1}
            />
            {invalidEmailAddress && <span style={styles.registrationErrorText}>Enter a valid email address.</span>}
          </div>
          <div style={styles.registrationDisclaimer}>
            {/*{brand?.text} will use your data to personalize and improve your TurningPoint+ experience and to send you
            information on TurningPoint+. You can change your communication preferences anytime. We will never sell your
            data, as described in our <a style={styles.registrationLink}>Privacy Policy</a>. By clicking &quot;Agree and
            Continue&quot;, you agree to our <a style={styles.registrationLink}>Terms and Conditions</a> and acknowledge
            that you have read our privacy policy.*/}
          </div>
          <div style={{ marginTop: 35 }}>
            <Button
              text={'agree and continue'}
              width={'100%'}
              height={36}
              disabled={
                !firstName || !lastName || !emailAddress || invalidFirstName || invalidLastName || invalidEmailAddress
              }
              arrowRight={true}
              onClick={advanceToPasswordSlide}
            />
          </div>
          <span style={styles.registrationMiscText}>
            Already have an account?{' '}
            <a
              style={{ ...styles.registrationLink, marginLeft: 4 }}
              onClick={() => navigate('/sign-in', { state: { redirectUrl } })}>
              Sign In
            </a>
          </span>
        </div>
      </div>
    )
  }

  const registrationSecondSlide = () => {
    return (
      <div style={styles.registrationSlide}>
        <div style={styles.registrationHeaderContainer}>
          <span style={styles.registrationHeader}>REGISTER</span>
          <div style={styles.registrationHeaderDivider} />
        </div>
        <div style={styles.registrationInputForm}>
          <span style={styles.registrationFormHeader}>Create a password</span>
          <div style={{ ...styles.registrationInputContainer, ...styles.registrationPasswordInputContainer }}>
            <label style={styles.registrationInputLabel}>Enter a New Password</label>
            <input
              type="password"
              id="password"
              name="password"
              autoComplete={'off'}
              placeholder={'At least six digits'}
              required
              value={password}
              onKeyDown={(e) => e.key === 'Enter' && handleOnRegister()}
              onBlur={validatePassword}
              onChange={setPassword}
              style={{
                ...styles.registrationInput,
                border: `3px solid ${invalidPassword ? '#FF4848' : 'transparent'}`,
              }}
              tabIndex={slideNumber === 2 ? undefined : -1}
              ref={passwordInputRef}
            />
            {invalidPassword && <span style={styles.registrationErrorText}>{invalidPassword}</span>}
          </div>
          <div
            style={{
              ...styles.registrationInputContainer,
              ...styles.registrationPasswordInputContainer,
              marginTop: 10,
            }}>
            <label style={styles.registrationInputLabel}>Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              autoComplete={'off'}
              required
              value={confirmPassword}
              onKeyDown={(e) => e.key === 'Enter' && handleOnRegister()}
              onBlur={validateConfirmPassword}
              onChange={setConfirmPassword}
              style={{
                ...styles.registrationInput,
                border: `3px solid ${invalidConfirmPassword ? '#FF4848' : 'transparent'}`,
              }}
              tabIndex={slideNumber === 2 ? undefined : -1}
            />
            {invalidConfirmPassword && <span style={styles.registrationErrorText}>Passwords must match.</span>}
          </div>
          <div style={{ marginTop: 35 }}>
            <Button
              text={'register'}
              width={'100%'}
              backgroundColor={brandColor}
              height={36}
              disabled={
                !password ||
                !confirmPassword ||
                !!invalidPassword ||
                invalidConfirmPassword ||
                password !== confirmPassword ||
                isSubmitting
              }
              onClick={handleOnRegister}
            />
          </div>
          <div style={{ marginTop: 25 }}>
            <Button
              text={'back to name and email'}
              width={'100%'}
              backgroundColor={brandColor}
              height={36}
              disabled={isSubmitting}
              onClick={backToNameAndEmailSlide}
            />
          </div>
        </div>
      </div>
    )
  }

  /*const registrationThirdSlide = () => {
    return (
      <div className={'registrationSlide'} style={styles.registrationSlide}>
        <div style={styles.registrationHeaderContainer}>
          <span style={styles.registrationHeader}>VERIFY</span>
          <div style={styles.registrationHeaderDivider} />
        </div>
        <div style={styles.registrationInputForm}>
          <span style={styles.registrationFormHeader}>Welcome, {firstName}!</span>
          <span style={styles.registrationFormSubtext}>We&apos;ve sent a code to {emailAddress}.</span>
          <span style={styles.registrationFormSubtext}>Enter it below to verify your email address:</span>
          <fieldset className={'registrationCodeContainer'} style={styles.registrationCodeContainer}>
            <div className={'registrationCode'} style={styles.registrationCode}>
              <input
                type={'number'}
                name="code"
                autoComplete={'off'}
                className={'registrationCodeInput'}
                style={styles.registrationCodeInput}
                minLength={1}
                maxLength={1}
                required
              />
              <input
                type={'number'}
                name="code"
                autoComplete={'off'}
                className={'registrationCodeInput'}
                style={styles.registrationCodeInput}
                minLength={1}
                maxLength={1}
                required
              />
              <input
                type={'number'}
                name="code"
                autoComplete={'off'}
                className={'registrationCodeInput'}
                style={styles.registrationCodeInput}
                minLength={1}
                maxLength={1}
                required
              />
              <input
                type={'number'}
                name="code"
                autoComplete={'off'}
                className={'registrationCodeInput'}
                style={styles.registrationCodeInput}
                minLength={1}
                maxLength={1}
                required
              />
              <input
                type={'number'}
                name="code"
                autoComplete={'off'}
                className={'registrationCodeInput'}
                style={styles.registrationCodeInput}
                minLength={1}
                maxLength={1}
                required
              />
              <input
                type={'number'}
                name="code"
                autoComplete={'off'}
                className={'registrationCodeInput'}
                style={styles.registrationCodeInput}
                minLength={1}
                maxLength={1}
                required
              />
            </div>
            {verifyCode.length === 6 && verifyCode !== matchedVerifyCode && (
              <span style={styles.registrationErrorText}>Invalid code. Please try again, or resend code below.</span>
            )}
          </fieldset>
          <div style={{ marginTop: 60 }}>
            <Button
              text={'verify'}
              width={'100%'}
              backgroundColor={brandColor}
              height={36}
              disabled={!verifyCode || verifyCode !== matchedVerifyCode}
              onClick={() => registrationSlider?.current?.slickNext()}
            />
          </div>
          <span style={styles.registrationMiscText}>
            Didn&apos;t receive a code? <a style={{ ...styles.registrationLink, marginLeft: 4 }}>Resend Email</a>
          </span>
        </div>
      </div>
    )
  }

  const registrationFourthSlide = () => {
    return (
      <div className={'registrationSlide'} style={styles.registrationSlide}>
        <div style={styles.registrationHeaderContainer}>
          <span style={styles.registrationHeader}>VERIFY</span>
          <div style={styles.registrationHeaderDivider} />
        </div>
        <div style={styles.registrationInputForm}>
          <span style={styles.registrationFormHeader}>
            <div style={styles.registrationVerifiedStatus}>
              <div className={'checkmark'} />
            </div>
            Your account is verified.
          </span>
          <span style={styles.registrationFormSubtext}>The address {emailAddress} is now a confirmed account.</span>
          <div style={{ marginTop: 50 }}>
            <Button
              text={'continue'}
              width={'100%'}
              height={36}
              arrowRight={true}
              onClick={() => navigate('/subscribe')}
            />
          </div>
        </div>
      </div>
    )
  }*/

  const registrationSettings = {
    dots: true,
    infinite: false,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: false,
    draggable: false,
    swipe: false,
    nextArrow: <></>,
    prevArrow: <></>,
  }

  /*const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  }*/

  const brandStyle = windowSize === WindowSize.Mobile ? { ...styles.brand, ...styles.mobileBrand } : styles.brand

  return (
    <div className={'registrationWrapper'} style={styles.container}>
      {brand ? (
        <div style={brandStyle}>
          <img src={brand.icon?.src} alt="brand" style={styles.logo} onClick={() => navigate('/')} />
        </div>
      ) : null}
      <div
        style={{
          maxWidth: 370,
          width: '100%',
          height: '100%',
          flex: 1,
          display: 'flex',
          marginTop: 25,
          marginBottom: 25,
        }}>
        <Slider ref={registrationSlider} {...registrationSettings}>
          {registrationFirstSlide()}
          {registrationSecondSlide()}
          {/*registrationThirdSlide()*/}
          {/*registrationFourthSlide()*/}
        </Slider>
      </div>
    </div>
  )
}

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 35,
    paddingRight: 35,
    paddingTop: 17,
  } as React.CSSProperties,
  logo: {
    height: process.env.REACT_APP_BRAND_HEIGHT || '100%',
    cursor: 'pointer',
  } as React.CSSProperties,
  brand: {
    height: 70,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  } as React.CSSProperties,
  mobileBrand: {
    height: 40,
  } as React.CSSProperties,
  registrationSlide: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  } as React.CSSProperties,
  registrationHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 50,
  } as React.CSSProperties,
  registrationHeader: {
    color: '#FFFFFF',
    marginRight: 15,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 1.8,
    textTransform: 'uppercase',
    opacity: 1,
    whiteSpace: 'nowrap',
  } as React.CSSProperties,
  registrationHeaderDivider: {
    backgroundColor: '#404040',
    width: '100%',
    height: 1,
  } as React.CSSProperties,
  registrationInputForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  } as React.CSSProperties,
  registrationFormHeader: {
    fontSize: 24,
    color: '#FFFFFF',
    fontWeight: 700,
    width: '100%',
    textAlign: 'left',
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
  } as React.CSSProperties,
  registrationInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 15,
    position: 'relative',
  } as React.CSSProperties,
  registrationPasswordInputContainer: {
    marginTop: 15,
    marginBottom: 35,
  } as React.CSSProperties,
  registrationInputLabel: {
    fontSize: 20,
    color: '#FFFFFF',
    fontWeight: 700,
    width: '100%',
    textAlign: 'left',
  } as React.CSSProperties,
  registrationInput: {
    width: '100%',
    boxSizing: 'border-box',
    height: 44,
    fontSize: 19,
    borderRadius: 5,
    border: 'none',
    padding: '0px 20px',
    marginTop: 15,
    background: 'rgba(255, 255, 255, 0.1)',
  } as React.CSSProperties,
  registrationDisclaimer: {
    textAlign: 'left',
    fontSize: 13,
    letterSpacing: 0,
    color: '#BFBFBF',
    opacity: 1,
    width: '100%',
    marginTop: 20,
    lineHeight: 1.4,
  } as React.CSSProperties,
  registrationLink: {
    borderBottom: '1px solid #FFFFFF',
    fontWeight: 500,
    color: '#FFFFFF',
    cursor: 'pointer',
  } as React.CSSProperties,
  registrationBtnText: {
    fontSize: 14,
    letterSpacing: 1.8,
    color: '#FFFFFF',
    opacity: 1,
    fontWeight: 600,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties,
  registrationMiscText: {
    textAlign: 'left',
    fontSize: 17,
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
    marginTop: 25,
    display: 'flex',
    alignItems: 'center',
  } as React.CSSProperties,
  registrationCodeContainer: {
    border: 'none',
    padding: 0,
    margin: 0,
    position: 'relative',
  } as React.CSSProperties,
  registrationCode: {
    display: 'flex',
    marginTop: 20,
  } as React.CSSProperties,
  registrationCodeInput: {
    width: 46,
    height: 65,
    border: 'none',
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 5,
    opacity: 1,
    textAlign: 'center',
    fontSize: 46,
    letterSpacing: 0,
    color: '#FFFFFF',
    marginRight: 10,
  } as React.CSSProperties,
  registrationFormSubtext: {
    textAlign: 'left',
    fontSize: 17,
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
    margin: '10px 0',
  } as React.CSSProperties,
  registrationVerifiedStatus: {
    width: 23,
    height: 23,
    background: '#55B685',
    opacity: 1,
    borderRadius: '50%',
    marginRight: 15,
  } as React.CSSProperties,
  registrationSubscriptionText: {
    fontSize: 48,
    color: '#FFFFFF',
    fontWeight: 700,
    width: '100%',
    textAlign: 'left',
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
    marginTop: -20,
  } as React.CSSProperties,
  registrationPriceContainer: {
    width: '100%',
    marginTop: 45,
    display: 'flex',
    alignItems: 'flex-end',
  } as React.CSSProperties,
  registrationPriceText: {
    textAlign: 'left',
    fontSize: 32,
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
    fontWeight: 700,
    lineHeight: '27px',
  } as React.CSSProperties,
  registrationDurationText: {
    textAlign: 'left',
    fontSize: 17,
    letterSpacing: 0,
    color: '#808080',
    opacity: 1,
    fontWeight: 500,
    marginLeft: 2,
  } as React.CSSProperties,
  registrationErrorText: {
    textAlign: 'left',
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0,
    color: '#FF4848',
    opacity: 1,
    marginTop: 8,
    width: '100%',
    position: 'absolute',
    bottom: -19,
    left: 0,
  } as React.CSSProperties,
  registrationBtn: {
    width: '100%',
    height: 39,
    opacity: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  btnRightArrow: {
    border: 'solid white',
    borderWidth: '0px 3px 3px 0px',
    display: 'inline-block',
    padding: 2.75,
    marginLeft: 4,
    transform: 'rotate(-45deg)',
  } as React.CSSProperties,
  btnLeftArrow: {
    border: 'solid white',
    borderWidth: '0px 3px 3px 0px',
    display: 'inline-block',
    padding: 2.75,
    marginRight: 4,
    transform: 'rotate(135deg)',
  } as React.CSSProperties,
}

export default Registration
