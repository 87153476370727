import React from 'react'

const PageNotFound = () => {
  return (
    <div className={'customWrapper'} style={styles.container}>
      <p>Page Not Found</p>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: 20,
  } as React.CSSProperties,
}

export default PageNotFound
