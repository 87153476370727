import React from 'react'
import { Controls as ControlsVid } from '@vidstack/react'
import * as Buttons from './Buttons'
import TimeGroup from './TimeGroup'
import * as Menus from './Menus'
import * as Sliders from './Sliders'
import { useMiniMode } from './MiniModeProvider'

const MobileVideoControls = () => {
  const [miniMode] = useMiniMode()

  return !miniMode ? (
    <>
      <ControlsVid.Group className={'vds-controls-group'}>
        <Buttons.Close tooltipPlacement={'bottom start'} />
        <div className={'vds-controls-spacer'} />
        <Buttons.Mute tooltipPlacement={'bottom'} />
        <Buttons.PIP tooltipPlacement={'bottom'} />
        <Menus.Settings placement={'bottom end'} tooltipPlacement={'bottom end'} />
      </ControlsVid.Group>
      <div className={'vds-controls-spacer'} />
      <ControlsVid.Group className={'vds-controls-group justify-center'}>
        <Buttons.Play tooltipPlacement={'top'} />
      </ControlsVid.Group>
      <div className={'vds-controls-spacer'} />
      <ControlsVid.Group className={'vds-controls-group'}>
        <TimeGroup miniMode={true} />
        <Buttons.Fullscreen tooltipPlacement={'top end'} />
      </ControlsVid.Group>
      <ControlsVid.Group className={'vds-controls-group'}>
        <Sliders.Time />
      </ControlsVid.Group>
    </>
  ) : null
}

export default MobileVideoControls
