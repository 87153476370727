import React from 'react'
import './index.css'
import ProgressBar from '../ProgressBar'
import { useNavigate } from 'react-router-dom'
import useLiveNow from '../../utils/hooks/useLiveNow'
import { ContentRestrictionsType, MediaItemFieldsFragment } from '../../graphql'

type MediaItemProps = {
  item: MediaItemFieldsFragment
  disableClick?: boolean
}

const MediaItem = ({ item, disableClick = false }: MediaItemProps) => {
  const navigate = useNavigate()
  const liveNow = useLiveNow(item.id)

  // todo: figure out what the hell is going on here with MediaItem. Why are the props media item but data is media item within the props???
  //const item = useMemo(() => data, [data])

  //const [item] = useState<MediaItem | undefined>(data)

  let eventStatus: 'live' | 'upcoming' | 'past' | undefined
  let timeRemainingText = ''
  let upcomingText = ''
  if (liveNow) {
    timeRemainingText = liveNow.minutesRemainingText
    //const timeElapsedText = liveNow.minutesElapsedText

    eventStatus = 'live'
  } else if (item.__typename === 'Event') {
    const now = new Date()
    const start = new Date(item.scheduledStart)
    const end = new Date(item.scheduledEnd)
    if (now < start) {
      eventStatus = 'upcoming'
      upcomingText = `Streaming live ${start.toLocaleString('default', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })}`
    } else if (now > end) {
      eventStatus = 'past'
    } else {
      eventStatus = 'live'
    }
  }

  let unpurchasedText = ''

  if (item?.restrictions?.includes(ContentRestrictionsType.Ppv)) {
    unpurchasedText = 'Available On-Demand'
  } else if (item?.restrictions?.includes(ContentRestrictionsType.Subscription)) {
    unpurchasedText = 'Subscribe to Watch'
  } else if (item?.restrictions?.includes(ContentRestrictionsType.Bundle)) {
    unpurchasedText = 'Available in Bundle'
  } else if (item?.restrictions?.includes(ContentRestrictionsType.Registration)) {
    unpurchasedText = 'Register to Watch'
  }

  let progress = eventStatus !== 'upcoming' ? item.viewer?.progress || liveNow?.progress || 0 : 0

  if (liveNow && item && (!('scheduledEnd' in item) || ('scheduledEnd' in item && !item?.scheduledEnd))) {
    timeRemainingText = ''
    progress = 1
  }

  const height = 187
  const width = 332

  return (
    <div className={'mediaItemWrapper'} style={styles.container}>
      <div
        className={'mediaItemContainer'}
        style={{
          ...styles.innerContainer,
          height,
          width,
          backgroundImage: `url('${item?.sixteenNineCover?.src}')`,
        }}
        onClick={() => {
          if (!disableClick) {
            navigate(`/content/${item?.id}`)
          }
        }}>
        {item?.restrictions?.length ? (
          <div className={'unpurchasedOverlay'} style={styles.unpurchasedOverlay} />
        ) : eventStatus === 'live' || eventStatus === 'upcoming' || progress > 0 ? (
          <div className={'overlay'} style={styles.overlay} />
        ) : null}
        {eventStatus === 'live' ? (
          <div
            className={'liveIndicatorContainer' + (item?.restrictions?.length ? ' unpurchasedBackground' : '')}
            style={styles.liveIndicatorContainer}>
            <div className={'indicator'} style={{ ...styles.indicator, background: '#FF4848' }} />
            <span className={'indicatorText'} style={styles.indicatorText}>
              live
            </span>
          </div>
        ) : eventStatus === 'upcoming' ? (
          <div
            className={'upcomingIndicatorContainer' + (item.restrictions?.length ? ' unpurchasedBackground' : '')}
            style={styles.upcomingIndicatorContainer}>
            <div className={'indicator'} style={{ ...styles.indicator, background: '#BFBFBF' }} />
            <span className={'indicatorText'} style={styles.indicatorText}>
              upcoming
            </span>
          </div>
        ) : null}
        {item.restrictions?.length ? (
          <>
            {(item.restrictions.includes(ContentRestrictionsType.Subscription) ||
              item.restrictions.includes(ContentRestrictionsType.Ppv) ||
              item.restrictions.includes(ContentRestrictionsType.Bundle)) && (
              <>
                <div className={'unpurchasedContainer'} style={styles.unpurchasedContainer} />
                <div className={'unpurchasedIcon'} />
              </>
            )}
            <span
              className={'unpurchasedText'}
              style={{
                ...styles.unpurchasedText,
              }}>
              {unpurchasedText}
            </span>
          </>
        ) : null}
        {progress ? (
          <div className={'progressBarWrapper'} style={styles.progressBarWrapper}>
            <div className={'progressBarContainer'} style={styles.progressBarContainer}>
              <span className={'progressBarText'} style={styles.progressBarText}>
                {timeRemainingText}
              </span>
              <ProgressBar progress={progress} live={eventStatus === 'live'} />
            </div>
          </div>
        ) : eventStatus === 'upcoming' ? (
          <span className={'upcomingText'} style={styles.upcomingText}>
            {upcomingText}
          </span>
        ) : null}
      </div>
      <span className={'mediaItemTitle'} style={styles.mediaItemTitle}>
        {item?.name}
      </span>
    </div>
  )
}

/*const poop = (
  <div
    className={'mediaItemWrapper'}
    style={{ ...styles.mediaItemWrapper, marginRight: props?.dropdown ? 0 : undefined }}>
    <div
      className={'mediaItemContainer'}
      style={{
        ...styles.mediaItemContainer,
        height,
        width,
        backgroundImage: `url('${item?.sixteenNineCover?.src}')`,
      }}
      onClick={() => navigate(`/content/${item?.id}`)}>
      {!purchased ? (
        <div className={'unpurchasedOverlay'} style={styles.unpurchasedOverlay} />
      ) : itemStatus === 'live' || itemStatus === 'upcoming' || progress > 0 ? (
        <div className={'overlay'} style={styles.overlay} />
      ) : null}
      {itemStatus === 'live' ? (
        <div
          className={'liveIndicatorContainer' + (!purchased ? ' unpurchasedBackground' : '')}
          style={styles.liveIndicatorContainer}>
          <div className={'indicator'} style={{ ...styles.indicator, background: '#FF4848' }} />
          <span className={'indicatorText'} style={styles.indicatorText}>
            live
          </span>
        </div>
      ) : itemStatus === 'upcoming' ? (
        <div
          className={'upcomingIndicatorContainer' + (!purchased ? ' unpurchasedBackground' : '')}
          style={styles.upcomingIndicatorContainer}>
          <div className={'indicator'} style={{ ...styles.indicator, background: '#BFBFBF' }} />
          <span className={'indicatorText'} style={styles.indicatorText}>
            upcoming
          </span>
        </div>
      ) : null}
      {!purchased ? (
        <>
          <div className={'unpurchasedContainer'} style={styles.unpurchasedContainer} />
          <div className={'unpurchasedIcon'} />
          <span className={'unpurchasedText'} style={styles.unpurchasedText}>
            {unpurchasedText}
          </span>
        </>
      ) : null}
      {progress ? (
        <div className={'progressBarWrapper'} style={styles.progressBarWrapper}>
          <div className={'progressBarContainer'} style={styles.progressBarContainer}>
            <span className={'progressBarText'} style={styles.progressBarText}>{`${timeLeft}m remaining`}</span>
            <ProgressBar progress={progress} live={itemStatus === 'live'} />
          </div>
        </div>
      ) : itemStatus === 'upcoming' ? (
        <span className={'upcomingText'} style={styles.upcomingText}>
          {upcomingText}
        </span>
      ) : null}
    </div>
  </div>
)*/

const styles = {
  container: {
    margin: '20px 12px 20px 0',
    display: 'flex',
    flexDirection: 'column',
  } as React.CSSProperties,
  innerContainer: {
    width: 332,
    height: 187,
    aspectRatio: '16 / 9',
    backgroundSize: 'cover',
    backgroundClip: 'content-box',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    position: 'relative',
    outline: '3px solid transparent',
    transition: 'all .3s ease-in-out',
    boxShadow: '7px 7px 5px #0000005A',
    cursor: 'pointer',
  } as React.CSSProperties,
  overlay: {
    background:
      'transparent linear-gradient(180deg, #000000 0%, #FFFFFF 0%, #040404 100%, #545454 100%) 0 0 no-repeat padding-box',
    mixBlendMode: 'multiply',
    opacity: 0,
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  upcomingIndicatorContainer: {
    position: 'absolute',
    width: 31,
    height: 31,
    top: 0,
    left: 0,
    backgroundColor: 'black',
    borderRadius: '0 0 15px 0',
    //backdropFilter: 'blur(10px)',
    //WebkitBackdropFilter: 'blur(10px)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  liveIndicatorContainer: {
    position: 'absolute',
    width: 31,
    height: 31,
    top: 0,
    left: 0,
    backgroundColor: 'black',
    borderRadius: '0 0 15px 0',
    //backdropFilter: 'blur(10px)',
    //WebkitBackdropFilter: 'blur(10px)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  indicator: {
    width: 11,
    height: 11,
    opacity: 1,
    borderRadius: '50%',
    position: 'absolute',
    left: 9,
  } as React.CSSProperties,
  indicatorText: {
    opacity: 0,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 1.8,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    marginLeft: 30,
    transition: 'all .1s ease-in-out',
  } as React.CSSProperties,
  mediaItemImg: {
    height: 'auto',
    width: '100%',
    alignSelf: 'center',
    justifySelf: 'center',
  } as React.CSSProperties,
  mediaItemTitle: {
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
    width: 300,
    height: 42,
    fontSize: 17,
    fontWeight: 500,
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
    marginLeft: 4,
    marginTop: 10,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'initial',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
  } as React.CSSProperties,
  unpurchasedOverlay: {
    position: 'relative',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    opacity: 0,
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  unpurchasedContainer: {
    position: 'absolute',
    right: '0%',
    top: '0%',
    width: '35%',
    height: '50%',
    transition: 'all .2s ease-in-out',
    background: 'transparent linear-gradient(45deg, #676767 0%, #404040 100%) 0% 0% no-repeat padding-box',
    clipPath: 'polygon(100% 0%, 100% 100%, 0% 0%)',
  } as React.CSSProperties,
  unpurchasedText: {
    color: '#FFFFFF',
    position: 'absolute',
    fontWeight: 700,
    fontSize: 20,
    bottom: 56,
    right: 20,
    opacity: 0,
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  upcomingText: {
    position: 'absolute',
    bottom: 12,
    right: 15,
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: 14,
    opacity: 0,
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  progressBarWrapper: {
    margin: '0 auto',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties,
  progressBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    height: 'auto',
    width: '101%',
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  progressBarText: {
    color: '#FFFFFF',
    justifySelf: 'flex-end',
    alignSelf: 'flex-end',
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 10,
    opacity: 0,
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
}

export default MediaItem
