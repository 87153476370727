import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from '../../store/auth/userSlice'
import { apiCreateUser, apiForgotPassword, apiResetPassword, apiSignIn, apiSignOut } from '../../services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from '../../store/auth/sessionSlice'
import appConfig from '../../configs/app.config'
import { REDIRECT_URL_KEY, REDIRECT_TOKEN_KEY } from '../../constants/app.constant'
import { useLocation, useNavigate } from 'react-router-dom'
import useParams from './useParams'
import { CreateUserInput, ForgotPasswordInput, LoginInput } from '../../graphql'
import { persistor } from '../../store'

function useAuth() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()

  const { token, signedIn } = useSelector<{ auth: { session: { token: string; signedIn: boolean } } }>(
    (state) => state.auth.session,
  ) as { token: string; signedIn: boolean }

  const signIn = async (data: LoginInput & { keepSignedIn: boolean }) => {
    let message = 'Something went wrong'
    try {
      const { keepSignedIn, ...rest } = data
      const resp = await apiSignIn(rest)
      const loginData = resp?.data?.login
      if (loginData) {
        const { token: tok, firstName, lastName, email, roles, customer } = loginData
        if (!keepSignedIn) {
          persistor.pause()
        }
        dispatch(onSignInSuccess({ token: tok, keepSignedIn }))
        dispatch(
          setUser({
            avatar: '',
            userName: `${firstName} ${lastName}`,
            authority: roles,
            email,
            subscriptions: customer?.subscriptions || [],
          }),
        )
        const redirectUrl: string = location.state?.redirectUrl
          ? location.state.redirectUrl
          : appConfig.authenticatedEntryPath
        navigate(redirectUrl)
        return {
          status: 'success',
          message: '',
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        message = error.message
      }
    }
    return {
      status: 'failed',
      message,
    }
  }

  const register = async (data: CreateUserInput) => {
    let message = 'Something went wrong'
    try {
      const resp = await apiCreateUser(data)
      const userData = resp?.data?.createUser
      if (userData) {
        await signIn({
          email: data?.email,
          password: data?.password as string,
          keepSignedIn: true,
        })
      }
    } catch (error) {
      if (error instanceof Error) {
        message = error.message
      }
    }
    console.log('message', message)
    return {
      status: 'failed',
      message,
    }
  }

  /*const signUp = async (data: RegisterTenantInput & LoginInput) => {
    let message = 'Something went wrong'
    try {
      let resp = await apiSignUp(data)
      const signUpData = resp.data?.registerTenant
      if (signUpData) {
        resp = await apiSignIn(data)
        const loginData = resp.data?.login
        if (loginData) {
          const { token: tok, firstName, lastName, email, roles } = loginData
          dispatch(onSignInSuccess(tok))
          if (resp.data.user) {
            dispatch(
              setUser({
                avatar: '',
                userName: `${firstName} ${lastName}`,
                authority: roles,
                email,
              }),
            )
          }
          const redirectUrl = params.get(REDIRECT_URL_KEY)
          navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
          return {
            status: 'success',
            message: '',
          }
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        message = error.message
      }
    }
    return {
      status: 'failed',
      message,
    }
  }*/

  //todo: finish refactoring signup method to apollo
  const handleSignOut = () => {
    dispatch(onSignOutSuccess())
    dispatch(setUser(initialState))
  }

  const signOut = () => {
    apiSignOut().finally(() => {
      handleSignOut()
    })
  }

  const forgotPassword = async (data: ForgotPasswordInput) => {
    let message = 'Something went wrong'
    try {
      const redirectUrl = params.get(REDIRECT_URL_KEY)
      if (!data.redirectUrl && redirectUrl) {
        data.redirectUrl = redirectUrl
      }
      const resp = await apiForgotPassword(data)
      if (resp?.data?.forgotPassword?.message) {
        return {
          status: 'success',
          message: resp?.data?.forgotPassword?.message,
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        message = error.message
      }
    }
    return {
      status: 'failed',
      message,
    }
  }

  const resetPassword = async (data: { password: string }) => {
    let message = 'Something went wrong'
    try {
      const tok = params.get(REDIRECT_TOKEN_KEY)
      const resp = await apiResetPassword({
        password: data.password,
        token: tok || '',
      })
      if (resp?.data?.message) {
        return {
          status: 'success',
          message: resp?.data?.message,
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        message = error.message
      }
    }
    return {
      status: 'failed',
      message,
    }
  }

  return {
    authenticated: token && signedIn,
    token,
    signIn,
    signOut,
    forgotPassword,
    resetPassword,
    register,
  }
}

export default useAuth
