type FormatTimeStringProps = {
  minutes?: number
  seconds?: number
}

export const formatTimeString = ({ minutes, seconds }: FormatTimeStringProps): string => {
  if (minutes !== undefined) {
    if (minutes === 0) {
      return ''
    }
    if (minutes < 60) {
      return `${minutes}m`
    }

    let timeLeftString = ''
    let timeLeftDays = 0
    let timeLeftHours = 0
    let timeLeftMinutes = 0

    timeLeftDays = Math.floor(minutes / 1440)
    timeLeftHours = Math.floor((minutes - timeLeftDays * 1440) / 60)
    timeLeftMinutes = Math.ceil(minutes - timeLeftDays * 1440 - timeLeftHours * 60)

    if (timeLeftDays) {
      timeLeftString += `${timeLeftDays}d `
    }
    if (timeLeftHours) {
      timeLeftString += `${timeLeftHours}h `
    }
    if (timeLeftMinutes) {
      timeLeftString += `${timeLeftMinutes}m`
    }

    return timeLeftString
  } else if (seconds !== undefined) {
    if (seconds === 0) {
      return ''
    }
    if (seconds < 60) {
      return `${seconds}s`
    }

    let timeLeftString = ''
    let timeLeftDays = 0
    let timeLeftHours = 0
    let timeLeftMinutes = 0
    let timeLeftSeconds = 0

    timeLeftDays = Math.floor(seconds / 86400)
    timeLeftHours = Math.floor((seconds - timeLeftDays * 86400) / 3600)
    timeLeftMinutes = Math.floor((seconds - timeLeftDays * 86400 - timeLeftHours * 3600) / 60)
    timeLeftSeconds = Math.ceil(seconds - timeLeftDays * 86400 - timeLeftHours * 3600 - timeLeftMinutes * 60)

    if (timeLeftDays) {
      timeLeftString += `${timeLeftDays}d `
    }
    if (timeLeftHours) {
      timeLeftString += `${timeLeftHours}h `
    }
    if (timeLeftMinutes) {
      timeLeftString += `${timeLeftMinutes}m `
    }
    if (timeLeftSeconds) {
      timeLeftString += `${timeLeftSeconds}s`
    }

    return timeLeftString
  }
  return ''
}

export const formatScheduledTimeRange = (start: Date, end: Date): string => {
  const sameDay = start.getDate() === end.getDate()

  let startHours = start.getHours()
  let startMinutes: string | number = start.getMinutes()
  const startAMPM = startHours >= 12 ? 'PM' : 'AM'
  const startDate = `${start.toLocaleString('default', {
    month: 'long',
  })} ${start.getDate()}, ${start.getFullYear()}`

  let endHours = end.getHours()
  let endMinutes: string | number = end.getMinutes()
  const endAMPM = endHours >= 12 ? 'PM' : 'AM'
  const endDate = `${end.toLocaleString('default', {
    month: 'long',
  })} ${end.getDate()}, ${end.getFullYear()}`

  startHours = startHours % 12
  startHours = startHours ? startHours : 12
  startMinutes = startMinutes < 10 ? '0' + startMinutes : startMinutes

  endHours = endHours % 12
  endHours = endHours ? endHours : 12
  endMinutes = endMinutes < 10 ? '0' + endMinutes : endMinutes

  let text = startDate + (!sameDay ? ' ' : ' from ') + startHours

  if (startMinutes !== '00' || endMinutes !== '00' || !sameDay) {
    text += ':' + startMinutes
  }

  if (!(startMinutes === '00' && endMinutes === '00' && sameDay && startAMPM === endAMPM)) {
    text += ' ' + startAMPM + ' – '
  } else {
    text += '–'
  }

  text += (!sameDay ? endDate + ' ' : '') + endHours

  if (startMinutes !== '00' || endMinutes !== '00' || !sameDay) {
    text += ':' + endMinutes
  }

  text += ' ' + endAMPM
  return text
}
