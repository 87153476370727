import React, { useState } from 'react'

const Button = (props: ButtonProps) => {
  const { type } = props
  const [hover, setHover] = useState(false)

  const opacity = hover && type === 'register' ? 1 : 0.9
  const transform = hover && type === 'register' ? 'scale(1.05)' : 'scale(1.0)'

  const {
    width,
    height,
    backgroundColor,
    color,
    borderColor,
    borderRadius,
    fontSize,
    fontWeight,
    buttonText,
    imgSrc,
    img2Src,
  } = props
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        width: width,
        height: height,
        opacity: opacity,
        transform: transform,
        color,
        backgroundColor,
        borderColor,
        borderRadius,
        fontSize,
        fontWeight,
        cursor: 'pointer',
      }}>
      <div style={imgSrc ? styles.withImgContainer : styles.commonContainer}>
        {imgSrc ? <img src={imgSrc} alt="image1" style={styles.leftImg} /> : null}
        {buttonText ? <div style={imgSrc ? styles.textWithImg : styles.textWithoutImg}>{buttonText}</div> : null}
        {img2Src ? <img src={img2Src} alt="image2" style={styles.rightImg} /> : null}
      </div>
    </div>
  )
}

const styles = {
  withImgContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 9,
  } as React.CSSProperties,
  commonContainer: {
    display: 'flex',
    justifyContent: 'center',
  } as React.CSSProperties,
  leftImg: {
    height: 17.5,
    width: 24,
    marginRight: 0,
    marginLeft: 15,
  } as React.CSSProperties,
  textWithImg: {
    marginTop: 1,
    marginLeft: 10.5,
    marginRight: 0,
    letterSpacing: 1.4,
  } as React.CSSProperties,
  textWithoutImg: {
    marginTop: 11,
    letterSpacing: 1.4,
  } as React.CSSProperties,
  rightImg: {
    width: 12,
    marginTop: 1,
    marginLeft: 6.5,
  } as React.CSSProperties,
}

export default Button
