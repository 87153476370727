import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useVerifyCheckoutSessionMutation } from '../../graphql'
import LoadingScreen from '../../common/LoadingScreen'

const VerifyPurchase = () => {
  const [searchParams] = useSearchParams()

  const contentId = searchParams.get('contentId')
  const bundleId = searchParams.get('bundleId')
  const priceId = searchParams.get('priceId')
  const checkoutSessionId = searchParams.get('checkoutSessionId')

  const navigate = useNavigate()

  const [verifyCheckoutSessionMutation] = useVerifyCheckoutSessionMutation({
    onCompleted: () => {
      navigate(`/content/${contentId}`)
    },
    onError: (error) => {
      // todo: handle failed purchase
      console.log(error)
    },
  })

  useEffect(() => {
    if (contentId && checkoutSessionId) {
      verifyCheckoutSessionMutation({
        variables: {
          data: {
            contentId,
            bundleId,
            priceId,
            checkoutSessionId,
          },
        },
      })
    }
  }, [contentId, checkoutSessionId])

  return <LoadingScreen />
}

export default VerifyPurchase
