import React from 'react'
import ReactDOM from 'react-dom/client'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import store from './store'
import DecoratedApolloProvider from './services'
import { createBrowserHistory } from 'history'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './common/error/ErrorFallback'
import { AnalyticsProvider, PlayerConfig } from 'react-video-analytics'

import '@vidstack/react/player/styles/default/theme.css'

import 'react-loading-skeleton/dist/skeleton.css'

import '@vime/core/themes/default.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

console.log('Running version:', process.env.REACT_APP_VERSION)

const history = createBrowserHistory()

root.render(
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onError={(error) => {
      console.log('hello there')
      console.log(error)
    }}>
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Router history={history}>
        <DecoratedApolloProvider>
          <AnalyticsProvider
            defaultPlayer={
              {
                getVideoElement: async (player: HTMLVideoElement | null) => {
                  return player
                },
              } as PlayerConfig<HTMLVideoElement | null>
            }>
            <App />
          </AnalyticsProvider>
        </DecoratedApolloProvider>
      </Router>
      {/*</PersistGate>*/}
    </Provider>
  </ErrorBoundary>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
