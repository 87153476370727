import React from 'react'
import Dropdown from '../../../common/Dropdown'
import useAuth from '../../../utils/hooks/useAuth'
import ProfileIcon from '../../../assets/ProfileIcon.svg'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../store/auth/userSlice'
import { selectWindowSize } from '../../../store/base/commonSlice'
import { WindowSize } from '../../../constants/enums'

type ProfileDropdownProps = {
  open: boolean
  windowSize: number
  signedIn?: boolean
}

const ProfileDropdown = ({ signedIn = false }: ProfileDropdownProps) => {
  const { signOut } = useAuth()
  const { userName, avatar } = useSelector(selectUser)
  const windowSize = useSelector(selectWindowSize)

  const items: Link[] = signedIn
    ? ([
        { text: 'My Library', route: { path: '/my-library', pageId: 'my-library' } },
        { text: 'My Account', route: { path: '/account', pageId: 'account' } },
        {
          text: 'Sign Out',
          onClick: () => signOut(),
        },
      ].filter((item) => item) as Link[])
    : [
        { text: 'Sign In', route: { path: '/sign-in', pageId: 'sign-in' } },
        { text: 'Register', route: { path: '/registration', pageId: 'registration' } },
      ]
  return (
    <Dropdown>
      {windowSize === WindowSize.Mobile ? (
        <div style={styles.buttonMobile}>
          <img src={ProfileIcon} alt={'icon'} style={styles.profileIcon} />
        </div>
      ) : (
        <div style={styles.button}>
          <div style={styles.userName}>{userName}</div>
          <div style={styles.item}>
            <img src={avatar ? avatar : ProfileIcon} alt="profile" style={styles.profileImage} />
          </div>
        </div>
      )}
      <Dropdown.Menu heading={windowSize === WindowSize.Mobile ? userName : undefined} items={items} />
    </Dropdown>
  )
}

const styles: CSSObject = {
  button: {
    display: 'flex',
    gap: 21,
    alignItems: 'center',
    cursor: 'pointer',
  },
  buttonMobile: {
    padding: 10,
    height: 17,
    cursor: 'pointer',
  },
  userName: {
    fontSize: 16,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  item: {
    marginRight: 0,
  },
  profileImage: {
    marginTop: 2.5,
    width: 35,
  },
  profileIcon: {
    height: 17,
  },
}

export default ProfileDropdown
