import { useLiveNowQuery, LiveNowQuery } from '../../graphql'
import { formatTimeString } from '../time'

export type LiveEvent = NonNullable<LiveNowQuery['liveNow']>[number] & {
  minutesRemaining: number | null
  minutesRemainingText: string
  progress: number
  minutesElapsed: number | null
  minutesElapsedText: string
}

function mapLiveEvent(event: NonNullable<LiveNowQuery['liveNow']>[number]) {
  const scheduledStart = event.scheduledStart ? new Date(event.scheduledStart) : null
  const scheduledEnd = event.scheduledEnd ? new Date(event.scheduledEnd) : null
  const now = new Date()
  const duration =
    scheduledEnd && scheduledStart ? Math.round(Math.abs(+scheduledEnd - +scheduledStart) / 1000 / 60) : null
  let minutesElapsed = scheduledStart ? Math.round(Math.abs(+now - +scheduledStart) / 1000 / 60) : null
  let minutesRemaining = scheduledEnd ? Math.round(Math.abs(+now - +scheduledEnd) / 1000 / 60) : null
  if (!duration || (minutesElapsed && minutesElapsed > duration)) {
    minutesElapsed = null
  }
  if (!duration || (minutesRemaining && minutesRemaining > duration)) {
    minutesRemaining = null
  }
  const progress = minutesElapsed && minutesRemaining ? minutesElapsed / (minutesElapsed + minutesRemaining) : 1
  const minutesElapsedText = minutesElapsed ? formatTimeString({ minutes: minutesElapsed }) + ' elapsed' : ''
  const minutesRemainingText = minutesRemaining ? formatTimeString({ minutes: minutesRemaining }) + ' remaining' : ''
  return {
    ...event,
    minutesElapsed,
    minutesRemaining,
    progress,
    minutesElapsedText,
    minutesRemainingText,
  }
}

function useLiveNow(id: string | null): LiveEvent | null
function useLiveNow(): LiveEvent[]

function useLiveNow(id?: string | null) {
  const { data } = useLiveNowQuery({
    fetchPolicy: 'cache-only',
  })
  if (id) {
    const liveEvent = data?.liveNow?.find((live) => live.id === id)
    if (liveEvent) {
      return mapLiveEvent(liveEvent)
    }
    return null
  } else if (id === null) {
    return null
  }
  return data?.liveNow?.map(mapLiveEvent) || []
}

export default useLiveNow
