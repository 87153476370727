import { ApolloLink, createHttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import config from '../configs/app.config'
import { REQUEST_HEADER_AUTH_KEY, TOKEN_TYPE } from '../constants/api.constant'
import { DecoratedClientProps } from './index'

const { api, tenantId } = config

const decorateErrorLink: (props: DecoratedClientProps) => ApolloLink = ({ auth, navigate }) =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(async ({ message, locations, path, extensions }) => {
        if (extensions.code === 'UNAUTHENTICATED') {
          setTimeout(() => navigate('sign-in', { state: { redirectUrl: location.pathname } }), 400)
          await auth.signOut()
        } else if (extensions.code !== 'FORBIDDEN') {
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        }
      })
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })

const decorateHttpLink: (props: DecoratedClientProps) => ApolloLink = () =>
  createHttpLink({
    uri: `${api.baseUrl}${api.graphQLPath}`,
  })

const decorateAuthLink: (props: DecoratedClientProps) => ApolloLink = ({ auth }) =>
  setContext((_, { headers }) => {
    //const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
    //const persistData = deepParseJson(rawPersistData)

    const accessToken = auth?.session?.token

    if (accessToken) {
      return {
        headers: {
          ...headers,
          [REQUEST_HEADER_AUTH_KEY]: `${TOKEN_TYPE} ${accessToken}`,
        },
      }
    }

    return {
      headers: {
        ...headers,
        [REQUEST_HEADER_AUTH_KEY]: tenantId,
      },
    }
  })

const decorateLink = (props: DecoratedClientProps) => {
  const errorLink = decorateErrorLink(props)
  const authLink = decorateAuthLink(props)
  const httpLink = decorateHttpLink(props)
  return from([errorLink, authLink, httpLink])
}

export default decorateLink
