import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../common/buttons/Button'
import { brandColor } from '../../style/brandConfig'

type RegistrationWallProps = {
  hideSignIn?: boolean
}

const RegistrationWall = ({ hideSignIn }: RegistrationWallProps) => {
  const navigate = useNavigate()
  return (
    <>
      {!hideSignIn && (
        <Button
          text={'sign in'}
          width={97}
          height={36}
          onClick={() =>
            navigate('/sign-in', {
              state: { redirectUrl: location.pathname },
            })
          }
        />
      )}
      <Button
        text={'register'}
        width={110}
        height={36}
        backgroundColor={brandColor}
        onClick={() =>
          navigate('/registration', {
            state: { redirectUrl: location.pathname },
          })
        }
      />
    </>
  )
}

export default RegistrationWall
