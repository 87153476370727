import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectPlayer } from '../../../store/video/playerSlice'
import { useMediaPlayer } from '@vidstack/react'
import { isMobile } from 'react-device-detect'
import usePrevious from '../../../utils/hooks/usePrevious'

const MiniModeContext = createContext<[boolean, (value: boolean) => void]>([
  false,
  () => {
    return
  },
])

export const useMiniMode = () => useContext(MiniModeContext)

type MiniModeProviderProps = {
  onMiniModeChange?: (value: boolean) => void
  children: React.ReactNode
}

const MiniModeProvider = ({ children, onMiniModeChange }: MiniModeProviderProps) => {
  const { status, src } = useSelector(selectPlayer)
  const player = useMediaPlayer()
  const [miniMode, setMiniMode] = useState(false)
  const prevMiniMode = usePrevious(miniMode)

  useEffect(() => {
    const body = document.querySelector('body')
    if (body) {
      body.style.overflowY = 'scroll'
    }
    if (miniMode) {
      miniModeOff()
    }
  }, [status, src])

  useEffect(() => {
    if (prevMiniMode !== miniMode) {
      onMiniModeChange?.(miniMode)
    }
  }, [miniMode, onMiniModeChange])

  const miniModeOn = () => {
    try {
      player?.exitFullscreen()
      const playerContainer = document.querySelector('.media-player-wrapper')
      document.ontouchmove = () => {
        return true
      }
      const body = document.querySelector('body')
      if (body) {
        body.style.overflowY = 'scroll'
      }
      if (playerContainer) {
        setMiniMode(true)
        const containerStyle = `
        position: fixed;
        bottom: 0px;
        right: 0px;
        width: 33.33%;
        height: auto;
        padding: 10px;
      `
        playerContainer.setAttribute('style', containerStyle)
      }
      setMiniMode(true)
    } catch (e) {
      console.log(e)
    }
  }

  const miniModeOff = () => {
    try {
      const playerContainer = document.querySelector('.media-player-wrapper')
      if (!isMobile) {
        const body = document.querySelector('body')
        if (body) {
          body.style.overflowY = 'hidden'
        }
      }
      if (playerContainer) {
        setMiniMode(false)
        const containerStyle = `
        position: fixed;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0px;
      `
        playerContainer.setAttribute('style', containerStyle)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <MiniModeContext.Provider
      value={[
        miniMode,
        (value: boolean) => {
          if (!miniMode && value) {
            miniModeOn()
          } else if (miniMode && !value) {
            miniModeOff()
          }
        },
      ]}>
      {children}
    </MiniModeContext.Provider>
  )
}

export default MiniModeProvider
