import { useEffect, RefObject } from 'react'

/**
 * Hook that alerts clicks outside of the passed ref
 */

type ClickAlerterOptions = {
  onOutsideClick?: () => void
  onInsideClick?: () => void
  exclude?: RefObject<HTMLElement>[]
}
const useClickAlerter = (
  ref: RefObject<HTMLElement>,
  { onOutsideClick, onInsideClick, exclude }: ClickAlerterOptions,
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClick = (event: MouseEvent) => {
      if (ref.current && event.target && !ref.current.contains(event.target as Node)) {
        for (const excluded of exclude || []) {
          if (excluded.current?.contains(event.target as Node)) {
            console.log('excluded!')
            return
          }
        }
        onOutsideClick?.()
      } else {
        onInsideClick?.()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClick)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClick)
    }
  }, [ref])
}

export default useClickAlerter
