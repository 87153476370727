import React, { useEffect, useState } from 'react'
import SearchIcon from '../../../assets/SearchIconGray.svg'
import { useSelector } from 'react-redux'
import { selectWindowSize } from '../../../store/base/commonSlice'
import { WindowSize } from '../../../constants/enums'

const SearchBar = (props: SearchBarProps) => {
  const { setSearch, text } = props
  const windowSize = useSelector(selectWindowSize)
  const [searchText, setSearchText] = useState(text)

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault()
    setSearchText(event.currentTarget.value)
  }

  useEffect(() => {
    if (searchText) {
      setSearch(searchText)
    } else {
      setSearch('')
    }
  }, [searchText])

  const placeholder = 'Search by title or category'
  return (
    <div
      style={
        windowSize !== WindowSize.Mobile
          ? {
              ...styles.container,
              marginLeft: 95,
              marginRight: 95,
            }
          : {
              ...styles.container,
              marginLeft: 50,
              marginRight: 30,
            }
      }>
      <img
        src={SearchIcon}
        alt={'Search Icon'}
        style={windowSize !== WindowSize.Mobile ? styles.searchIcon : styles.searchIconMobile}
      />
      <input
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
        value={text}
        style={
          windowSize !== WindowSize.Mobile
            ? {
                ...styles.searchBar,
                color: text === placeholder ? '#A7A7A7' : '#FFFFFF',
              }
            : styles.searchBarMobile
        }
      />
    </div>
  )
}

const styles = {
  container: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
  } as React.CSSProperties,
  searchBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    height: 55,
    marginTop: 95,
    width: '100%',
    borderRadius: 5,
    border: '1px solid transparent',
    outline: 'none',
    background: 'rgba(255, 255, 255, 0.1)',
    opacity: 1,
    color: '#A7A7A7',
    paddingLeft: 55,
    font: 'SF Pro Text',
    fontSize: 25,
    fontWeight: 400,
    zIndex: 15,
  } as React.CSSProperties,
  searchBarMobile: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    height: 55,
    marginTop: 65,
    width: '100%',
    borderRadius: 5,
    border: '1px solid transparent',
    outline: 'none',
    background: 'rgba(255, 255, 255, 0.1)',
    opacity: 1,
    color: '#A7A7A7',
    paddingLeft: 54,
    font: 'SF Pro Text',
    fontSize: 18,
    fontWeight: 400,
    zIndex: 15,
  } as React.CSSProperties,
  searchIcon: {
    marginTop: 95,
    marginRight: -45,
    width: 25,
    zIndex: 3,
  } as React.CSSProperties,
  searchIconMobile: {
    marginRight: -40,
    marginTop: 65,
    width: 20,
    zIndex: 1,
  } as React.CSSProperties,
}

export default SearchBar
