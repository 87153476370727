import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

type ExploreAllState = {
  open: boolean
  id: string | null
  catalogId: string | null
  search?: string | null
}
export const initialState: ExploreAllState = {
  open: false,
  id: null,
  catalogId: null,
}

export const exploreAllSlice = createSlice({
  name: 'exploreAll',
  initialState,
  reducers: {
    setExploreAll: (state, action: PayloadAction<ExploreAllState>) => {
      state.open = action.payload.open
      state.id = action.payload.id
      state.catalogId = action.payload.catalogId
      if (action.payload.search !== undefined) {
        state.search = action.payload.search
      }
    },
  },
})

export const { setExploreAll } = exploreAllSlice.actions

export const selectExploreAll = createSelector(
  (state: { exploreAll: { exploreAll: ExploreAllState } }) => state.exploreAll.exploreAll,
  (exploreAll) => exploreAll,
)

export default exploreAllSlice.reducer
