import React from 'react'
import Button from '../../../common/buttons'
import LiveNowIcon from '../../../assets/LiveNowIndicatorMobile.svg'
import DropdownArrow from '../../../assets/DropdownArrow.svg'

const LiveNowButton = () => {
  return (
    <Button
      backgroundColor={'#4D1616'}
      color={'#ffffff'}
      opacity={'100%'}
      buttonText={'LIVE NOW'}
      width={160}
      height={35}
      fontSize={13}
      font={'SF Pro Text'}
      fontWeight={600}
      borderRadius={18}
      imgSrc={LiveNowIcon}
      img2Src={DropdownArrow}
      hover={false}
    />
  )
}

export default LiveNowButton
