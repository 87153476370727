import React from 'react'
import { ProductFieldsFragment } from '../../../graphql'

type SubscriptionInfoProps = {
  product: ProductFieldsFragment
}

const SubscriptionInfo = ({ product }: SubscriptionInfoProps) => {
  return (
    <>
      <span style={styles.productNameText}>{product.name}</span>
      {product.description && (
        <span style={{ ...styles.productMiscText, marginBottom: 20 }}>{product.description}</span>
      )}
    </>
  )
}

export default SubscriptionInfo

const styles = {
  productNameText: {
    fontSize: 40,
    color: '#FFFFFF',
    fontWeight: 700,
    width: '100%',
    textAlign: 'left',
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
  } as React.CSSProperties,
  productMiscText: {
    textAlign: 'left',
    fontSize: 17,
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
    marginTop: 25,
    display: 'flex',
    alignItems: 'center',
  } as React.CSSProperties,
}
