import React, { useEffect, useState } from 'react'
import MediaRow from '../../common/MediaRow'
import SearchBar from './components/SearchBar'
import SearchBarScrolling from './components/SearchBarScrolling'
import { useExploreLazyQuery } from '../../graphql'
import { useDispatch, useSelector } from 'react-redux'
import { selectWindowSize } from '../../store/base/commonSlice'
import { WindowSize } from '../../constants/enums'
import LoadingScreen from '../../common/LoadingScreen'
import debounce from 'lodash.debounce'
import { selectExploreAll, setExploreAll } from '../../store/exploreAll/exploreAllSlice'
import { setHeader } from '../../store/base/commonSlice'

type ExplorePage = {
  scrolling: boolean
  brandedName: string
}

const ExplorePage = ({ scrolling }: ExplorePage) => {
  const dispatch = useDispatch()
  const exploreAllState = useSelector(selectExploreAll)
  const windowSize = useSelector(selectWindowSize)
  const [search, setSearch] = useState('')

  const [explore, { data, loading }] = useExploreLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    explore()
  }, [])

  useEffect(() => {
    dispatch(setHeader('search'))
    return () => {
      dispatch(setHeader('normal'))
    }
  }, [dispatch])

  const debounceHandleSearch = debounce(handleSearch, 500)

  async function handleSearch() {
    dispatch(
      setExploreAll({
        open: exploreAllState.open,
        id: exploreAllState.id,
        catalogId: exploreAllState.catalogId,
        search: search,
      }),
    )
    const exploreData = {
      search,
    }
    await explore({
      variables: {
        mediaRowData: exploreData,
        mediaItemData: exploreData,
      },
    })
  }

  useEffect(() => {
    debounceHandleSearch()
  }, [search])

  const contentMatchesSearch = data?.explore?.rows.some((row) => row?.items.list.length)

  let rows = data?.explore?.rows.filter((row) => row?.items.list.length) || []
  const rowItemIds = rows.map((row) => row?.items.list.map((item) => item.id))

  const indexesToRemove: number[] = []

  for (const index in rowItemIds) {
    if (rows[index]?.items.hasMore) {
      continue
    }
    const ids = rowItemIds[index]
    const firstIndex = rowItemIds.findIndex((_ids) => JSON.stringify(_ids) === JSON.stringify(ids))
    if (firstIndex !== parseInt(index)) {
      indexesToRemove.push(parseInt(index))
    }
  }

  rows = rows.filter((_, index) => !indexesToRemove.includes(index))

  return (
    <div style={styles.container}>
      {!scrolling ? (
        <div style={{ position: 'fixed', top: 15, width: '100%', zIndex: 3 }}>
          <SearchBar text={search} setSearch={setSearch} />
        </div>
      ) : (
        <div style={{ position: 'fixed', top: 15, width: '100%', zIndex: 3 }}>
          <SearchBarScrolling text={search} setSearch={setSearch} />
        </div>
      )}
      {search === '' ? (
        <div style={windowSize !== WindowSize.Mobile ? styles.header : styles.headerMobile}>Explore</div>
      ) : (
        <div
          style={{
            ...styles.searchHeaderWrapper,
            marginTop: windowSize === WindowSize.Mobile ? 200 : 300,
            paddingLeft: windowSize === WindowSize.Mobile ? 25 : 95,
            paddingRight: windowSize === WindowSize.Mobile ? 25 : 95,
          }}>
          <div style={{ ...styles.divider, marginRight: 20 }} />
          <div style={windowSize !== WindowSize.Mobile ? styles.searchHeader : styles.searchHeaderMobile}>
            {loading ? 'LOADING' : contentMatchesSearch ? 'TOP RESULTS' : 'NO RESULTS'}
          </div>
          <div style={{ ...styles.divider, marginLeft: 20 }} />
        </div>
      )}
      <LoadingScreen loading={loading && !data}>
        <div className={'exploreWrapper'} style={styles.container}>
          <div className={'mediaRowWrapper'} style={styles.mediaRowWrapper}>
            {rows.map((row, index) => {
              return <MediaRow key={index} {...row} />
            })}
          </div>
        </div>
      </LoadingScreen>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  } as React.CSSProperties,
  backgroundImage: {
    backgroundImage:
      'linear-gradient(to bottom, transparent, #0D0D0D), url(https://i.picsum.photos/id/397/1920/1080.jpg?hmac=edw4LTaXPWwrkbGBZjjHmToKh4Y1Wd8ASEaFbqeKiyY)',
    backgroundRepeat: 'cover',
    height: '55vh',
    width: '100%',
    zIndex: 1,
  } as React.CSSProperties,
  backgroundImageMobile: {
    backgroundImage:
      'linear-gradient(to bottom, transparent, #0D0D0D), url(https://i.picsum.photos/id/397/1920/1080.jpg?hmac=edw4LTaXPWwrkbGBZjjHmToKh4Y1Wd8ASEaFbqeKiyY)',
    backgroundRepeat: 'cover',
    height: '22vh',
    width: '100%',
    zIndex: 1,
  } as React.CSSProperties,
  searchHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  } as React.CSSProperties,
  searchResultsHeaderDivider: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#404040',
    marginTop: 305,
    height: 1,
    zIndex: 1,
    width: '250%',
    marginLeft: 'auto',
    marginRight: 'auto',
    flexShrink: 2,
  } as React.CSSProperties,
  searchResultsHeaderDividerMobile: {
    backgroundColor: '#404040',
    width: '105%',
    marginTop: 305,
    height: 1,
    zIndex: 1,
  } as React.CSSProperties,
  header: {
    color: 'white',
    marginTop: 300,
    marginRight: 5,
    marginLeft: 5,
    fontWeight: 700,
    fontSize: 64,
    zIndex: 1,
    opacity: 1,
    cursor: 'hover',
    flexWrap: 'nowrap',
  } as React.CSSProperties,
  headerMobile: {
    color: 'white',
    fontWeight: 700,
    fontSize: 28,
    backgroundColor: 'transparent',
    width: '100%',
    zIndex: 1,
    marginTop: 180,
    opacity: 1,
    cursor: 'hover',
    flexWrap: 'nowrap',
  } as React.CSSProperties,
  divider: {
    height: 1,
    flex: 1,
    backgroundColor: '#404040',
  },
  searchHeader: {
    color: 'white',
    fontWeight: 500,
    fontSize: 18,
    zIndex: 1,
    opacity: 1,
    letterSpacing: 3.6,
    cursor: 'hover',
  } as React.CSSProperties,
  searchHeaderMobile: {
    color: 'white',
    fontWeight: 500,
    fontSize: 14,
    zIndex: 1,
    opacity: 1,
    letterSpacing: 2.8,
    cursor: 'hover',
  } as React.CSSProperties,
  mediaRowWrapper: {
    overflow: 'hidden',
    width: '100%',
    marginTop: '44px',
    marginBottom: '44px',
    backgroundColor: 'transparent',
  } as React.CSSProperties,
}

export default ExplorePage
