import React from 'react'
import useLiveNow from '../../utils/hooks/useLiveNow'
import { MediaItemFieldsFragment } from '../../graphql'
import ProgressBar from '../ProgressBar'
import { WindowSize } from '../../constants/enums'
import { useSelector } from 'react-redux'
import { selectWindowSize } from '../../store/base/commonSlice'

type MediaItemCoverProps = {
  item: MediaItemFieldsFragment
  size?: 'sm' | 'md'
  block?: boolean
  hover?: boolean
}

type LiveIndicatorProps = {
  show?: boolean
  windowSize?: number
}

const LiveIndicator = ({ show = false, windowSize }: LiveIndicatorProps) => {
  const liveIndicatorContainerStyle =
    windowSize === WindowSize.Mobile
      ? {
          ...styles.liveIndicatorContainer,
          ...styles.liveIndicatorContainerMobile,
        }
      : {
          ...styles.liveIndicatorContainer,
        }

  const liveIndicatorStyle =
    windowSize === WindowSize.Mobile
      ? {
          ...styles.liveIndicator,
          ...styles.liveIndicatorMobile,
        }
      : {
          ...styles.liveIndicator,
        }

  return show ? (
    <div style={liveIndicatorContainerStyle}>
      <div style={liveIndicatorStyle} />
      <span style={styles.liveIndicatorText}>live</span>
    </div>
  ) : null
}

const MediaItemCover = ({ item, size = 'md', hover = false, block = false }: MediaItemCoverProps) => {
  const liveEvent = useLiveNow(item.id)
  const windowSize = useSelector(selectWindowSize)

  const containerStyle = {
    ...styles.container,
    width: block ? '100%' : size === 'sm' ? (windowSize === WindowSize.Mobile ? 131 : 150) : 332,
    aspectRatio: '16/9',
    transform: hover ? 'scale(1.07)' : 'scale(1)',
    outlineColor: hover ? 'white' : 'transparent',
  }

  const coverSrc = item.sixteenNineCover?.src

  const progress = liveEvent ? liveEvent.progress : item.viewer?.progress

  return (
    <div style={{}}>
      <div style={containerStyle}>
        <LiveIndicator show={!!liveEvent} windowSize={windowSize} />
        <ProgressBar progress={progress} live={!!liveEvent} />
        {coverSrc ? <img src={coverSrc} alt={'cover'} style={styles.coverImage} /> : <div style={styles.coverImage} />}
      </div>
    </div>
  )
}

const styles: CSSObject = {
  container: {
    display: 'flex',
    position: 'relative',
    transition: 'all .3s ease-in-out',
    outlineStyle: 'solid',
    outlineWidth: 3,
    outlineColor: 'transparent',
  },
  cover: {
    display: 'flex',
    flex: 1,
    //width: '100%',
    //height: '100%',
  },
  coverImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },
  liveIndicatorContainer: {
    display: 'flex',
    position: 'absolute',
    width: 31,
    height: 31,
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    backgroundColor: 'black',
    borderRadius: '0 0 15px 0',
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all .3s ease-in-out',
  },
  liveIndicatorContainerMobile: {
    width: 25,
    height: 25,
  },
  liveIndicator: {
    width: 11,
    height: 11,
    opacity: 1,
    borderRadius: '50%',
    backgroundColor: '#FF4848',
  },
  liveIndicatorMobile: {
    width: 8,
    height: 8,
  },
  liveIndicatorText: {
    display: 'none',
  },
}

export default MediaItemCover
