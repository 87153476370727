import { client, handleErrors } from './index'
import {
  LoginDocument,
  LogoutDocument,
  RegisterTenantDocument,
  LoginInput,
  RegisterTenantInput,
  ResetPasswordInput,
  ResetPasswordDocument,
  ForgotPasswordInput,
  ForgotPasswordDocument,
  CreateUserInput,
  CreateUserDocument,
} from '../graphql'

export async function apiSignIn(data: LoginInput) {
  return handleErrors(
    client?.mutate({
      mutation: LoginDocument,
      variables: {
        data,
      },
    }),
  )
}

export async function apiSignUp(data: RegisterTenantInput) {
  return handleErrors(
    client?.mutate({
      mutation: RegisterTenantDocument,
      variables: {
        data,
      },
    }),
  )
}

export async function apiSignOut() {
  return handleErrors(
    client?.mutate({
      mutation: LogoutDocument,
    }),
  )
}

export async function apiForgotPassword(data: ForgotPasswordInput) {
  return handleErrors(
    client?.mutate({
      mutation: ForgotPasswordDocument,
      variables: {
        data,
      },
    }),
  )
}

export async function apiResetPassword(data: ResetPasswordInput) {
  return handleErrors(
    client?.mutate({
      mutation: ResetPasswordDocument,
      variables: {
        data,
      },
    }),
  )
}

export async function apiCreateUser(data: CreateUserInput) {
  return handleErrors(
    client?.mutate({
      mutation: CreateUserDocument,
      variables: {
        data,
      },
    }),
  )
}
