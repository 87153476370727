import React from 'react'
import Button from './Button'
import { brandColor } from '../../style/brandConfig'
import { useNavigate } from 'react-router-dom'

type RegisterButtonProps = {
  redirectUrl?: string
}

const RegisterButton = ({ redirectUrl = '' }: RegisterButtonProps) => {
  const navigate = useNavigate()

  return (
    <Button
      text={'register'}
      backgroundColor={brandColor}
      width={110}
      height={39}
      onClick={() => navigate('/registration', { state: { redirectUrl } })}
    />
  )
}

export default RegisterButton
