import React from 'react'
import Carousel from '../common/Carousel'
import MediaRow from '../common/MediaRow'
import { useHomeQuery } from '../graphql'
import LoadingScreen from '../common/LoadingScreen'

const Home = () => {
  const { data, loading } = useHomeQuery({
    fetchPolicy: 'cache-and-network',
  })

  return (
    <LoadingScreen loading={loading && !data}>
      <div className={'homeWrapper'} style={styles.container}>
        {data?.home?.featured.length ? <Carousel items={data.home.featured} /> : null}
        <div className={'mediaRowWrapper'} style={styles.mediaRowWrapper}>
          {data?.home?.rows.map((row, index) => {
            if (row.items.list.length) {
              return <MediaRow key={index} {...row} />
            }
          })}
        </div>
      </div>
    </LoadingScreen>
  )
}

const styles = {
  container: {
    width: '100%',
    marginTop: 70,
  },
  mediaRowWrapper: {
    overflow: 'hidden',
    width: '100%',
    marginTop: '44px',
    marginBottom: '44px',
  },
}

export default Home
