export const formatAmount = (cents: number | undefined): string | undefined => {
  if (!cents) return undefined
  return (cents / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}

export const formatInterval = (interval: string | undefined): string | undefined => {
  switch (interval) {
    case 'month':
      return 'mo'
    case 'year':
      return 'yr'
    default:
      return interval
  }
}
