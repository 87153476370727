import { useGlobalQuery } from '../../graphql'

function useGlobal() {
  const { data } = useGlobalQuery({
    fetchPolicy: 'cache-only',
  })
  return (
    data?.global || {
      header: null,
      footer: null,
    }
  )
}

export default useGlobal
