import { client } from '../index'
import { LiveUpcomingDocument, LiveUpcomingQuery } from '../../graphql'
import { ObservableQuery } from '@apollo/client'
import { LIVE_UPCOMING_INTERVAL } from '../../constants/service.constant'

let query: ObservableQuery<LiveUpcomingQuery> | undefined
let _onNext: ((liveUpcoming: boolean) => void) | undefined
let _maxRetries: number

type LiveUpcomingParams = {
  pollInterval?: number
  onNext?: typeof _onNext
  maxRetries?: typeof _maxRetries
}

function start(
  { onNext, pollInterval = LIVE_UPCOMING_INTERVAL, maxRetries = 3 }: LiveUpcomingParams,
  retryAttempts = 0,
) {
  _onNext = onNext
  _maxRetries = maxRetries
  if (!query) {
    query = client?.watchQuery({
      query: LiveUpcomingDocument,
      fetchPolicy: 'network-only',
    })
    const subscription = query?.subscribe({
      next: ({ data }) => {
        onNext?.(!!data.global?.liveUpcoming)
      },
      error: () => {
        // Retry service on error
        subscription?.unsubscribe()
        // Max retries prevents infinite recursion
        if (retryAttempts < maxRetries) {
          start({ onNext, pollInterval, maxRetries }, retryAttempts + 1)
        } else {
          query?.stopPolling()
        }
      },
    })
  }
  query?.startPolling(pollInterval)
}

function update(pollInterval: number) {
  start({
    onNext: _onNext,
    pollInterval,
    maxRetries: _maxRetries,
  })
}

function stop() {
  query?.stopPolling()
}

export default {
  start,
  update,
  stop,
}
