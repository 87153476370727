import { createSelector, createSlice } from '@reduxjs/toolkit'

type SessionState = {
  token: string
  keepSignedIn: boolean
  signedIn: boolean
}

const initialState: SessionState = {
  token: '',
  keepSignedIn: false,
  signedIn: false,
}

export const sessionSlice = createSlice({
  name: 'auth/session',
  initialState,
  reducers: {
    onSignInSuccess: (state, action) => {
      state.signedIn = true
      state.token = action.payload.token
      state.keepSignedIn = action.payload.keepSignedIn
    },
    onSignOutSuccess: (state) => {
      state.signedIn = false
      state.token = ''
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
  },
})

export const selectSession = createSelector(
  (state: { auth: { session: SessionState } }) => {
    return state.auth.session
  },
  (session) => session,
)

export const { onSignInSuccess, onSignOutSuccess, setToken } = sessionSlice.actions

export default sessionSlice.reducer
